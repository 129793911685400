const user = [{
  path: '/user/profile',
  name: 'user-profile',
  component: () => import('@/views/user/Profile.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/user/team',
  name: 'user-team',
  component: () => import('@/views/user/Team.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/user/refer',
  name: 'user-refer',
  component: () => import('@/views/user/ReferAFriend.vue'),
  meta: {
    layout: 'content',
  },
},
]

export default user
