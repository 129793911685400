const dashboards = [
  {
    path: '/dashboard/dashboardhub',
    name: 'dashboard-hub',
    component: () => import('@/views/dashboards/dashboardHub.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboard/documentdashboard',
    name: 'dashboard-document',
    component: () => import('@/views/dashboards/documentDashboard.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboard/logsdashboard',
    name: 'dashboard-logs',
    component: () => import('@/views/dashboards/LoggerDashboard.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboard/medscheckdashboard',
    name: 'dashboard-medscheck',
    component: () => import('@/components/common/Dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/dashboard/rxdashboard',
    name: 'dashboard-rx',
    component: () => import('@/views/dashboards/Rx.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboard/mixturesdashboard',
    name: 'dashboard-mixtures',
    component: () => import('@/views/dashboards/Compounds.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default dashboards
