import { mdiBookOpenPageVariantOutline, mdiCloudCheckOutline, mdiGoogleAnalytics } from '@mdi/js'

export default [
  {
    title: 'Portals',
    icon: mdiCloudCheckOutline,

    // badge: '3',
    // badgeColor: 'warning',
    children: [
      {
        title: 'Phox Dash',
        icon: mdiGoogleAnalytics,
        href: 'https://dash.pharmaguide.ca/',
      },
      {
        title: 'Phox Wiki',
        href: 'https://wiki.pharmaguide.ca/',
        icon: mdiBookOpenPageVariantOutline,
      },
    ],
  },
]
