import axios from '@/render/api/http'
import config from './config'

const getAllShipments = async (accessToken, pharmacyId) => {
  try {
    // TODO: change this back to config.api
    // return await axios.get(`http://localhost:3000/shipping/all`).then((response) => response.data)
    return await axios.get(`${config.api}shipping/all?pharmacyID=${pharmacyId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(response => response.data)
  } catch (e) {
    console.log(e)
  }
}

const getShipment = async orderNumber =>

// return await axios.get(`http://localhost:3000/shipping/shipment`, {
  await axios.get(`${config.api}/shipping/shipment`, {
    params: {
      orderNumber,
    },
  }).then(res => res.data)

export default {
  getShipment,
  getAllShipments,
}
