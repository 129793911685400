const support = [{
  path: '/support/comingsoon',
  name: 'support-comming-soon',
  component: () => import('@/views/support/ComingSoon.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/support/faq',
  name: 'support-faq',
  component: () => import('@/views/support/Faq.vue'),
  meta: {
    layout: 'content',
  },
},

// There are two support videos links, one with a param and one without
{
  path: '/support/videos/:videoId',
  name: 'support-videos',
  component: () => import('@/views/support/SupportVideos.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/support/video',
  name: 'support-video',
  component: () => import('@/views/support/SupportVideos.vue'),
  meta: {
    layout: 'content',
  },
},
]

export default support
