import axios from '../http'
import config from './config'

const getPdf = async (pdfID,accessToken) => await axios.get(`${config.api}pdf/${pdfID}`, {
  responseType: 'arraybuffer',
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/pdf',
  },
}).then(resp => resp.data)

export default {
  getPdf,
}
