import axios from '../http'
import config from './config'

// FIXME then and catch should be DRY
const createState = async (accessToken, {
    formType,
    state,
    patientID,
    userID,
    pharmacyID,
}) => await axios.post(
    `${config.api}state/create`, {
        formType,
        state,
        patientID,
        userID,
        pharmacyID,
    }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
).then(res => res.data)

const updateState = (accessToken, stateID, {
    formType,
    status,
    state,
    patientID,
    userID,
    pharmacyID,
}) => axios.put(
    `${config.api}state/update`, {
        _id: stateID,
        formType,
        state,
        status,
        patientID,
        userID,
        pharmacyID,
    }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
)
    .then(res => res.data)

const getStateById = async (accessToken, stateId) => {
    // FIXME add pharmacyID filter to backend
    try {
        return await axios.get(
            `${config.api}state/${stateId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            },
        ).then(res => res.data.state)
    } catch (e) {
        throw new Error(e)
    }
}

const getStateByStatus = async (accessToken, status) => {
    try {
        const res = await axios.get(`${config.api}state/all?status=${status}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}
const getStateByFormType = async (formType, pharmacyId, token) => {
    try {
        const res = await axios.get(`${config.api}state/all?formType=${formType}&pharmacyId=${pharmacyId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}
const getStateByPatientID = async (patientID, pharmacyId, token) => {
    try {
        const res = await axios.get(`${config.api}state/patientAll?patientID=${patientID}&pharmacyId=${pharmacyId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}
const getStateByFormTypeAndLimit = async (formType, limit) => {
    try {
        const res = await axios.get(`${config.api}state/all/?formType=${formType}&limit=${limit}`)

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const deleteStateById = async (stateId, token) => {
    try {
        return await axios.delete(`${config.api}state/${stateId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    } catch (e) {
        throw new Error(e)
    }
}
const duplicateDoc = async (stateId, date, token) => {
    try {
        return await axios.post(`${config.api}state/duplicate/${stateId}`, { date }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    } catch (e) {
        throw new Error(e)
    }
}

const methods = {
    createState,
    updateState,
    getStateById,
    getStateByStatus,
    deleteStateById,
    getStateByFormType,
    getStateByFormTypeAndLimit,
    duplicateDoc,
    getStateByPatientID,
}

// if(process.env.NODE_ENV !== 'production'){
//   const mockWhiteList = [
//     'createState',
//     'updateState',
//     'duplicateDoc'
//   ]

//   const mockStatePromise = (methodName, args) => {
//     console.info(`MOCK >> ${methodName} :: `, ...args);
//     return Promise.resolve({
//         state: {
//           _id: 'mockStateID'
//       }
//     });
//   }
//   // override those with mock functions
//   methods = Object.keys(methods).reduce( (obj, methodName ) => {
//     if(mockWhiteList.includes(methodName)){
//       obj = {
//         ...obj,
//         [methodName]: (...args) => { return mockStatePromise(methodName, args) }
//       }
//     }

//     return obj;
//   }, methods)
// }

export default methods
