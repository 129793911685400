const initialState = {
  patients: [],
  FORM_CLASS: '',
  when: '',
  group: '',
}

const state = { ...initialState }

const mutations = {
  SET_PATIENTS_LIST(state, payload) {
    state.patients = payload
  },
  SET_GROUP(state, payload) {
    state.group = payload
  },
  SET_FORM_CLASS(state, payload) {
    state.FORM_CLASS = payload
  },
  SET_DATA_TIME(state, payload) {
    const d = new Date()
    state.when = `${d.getFullYear()}-${d.getMonth()}-${d.getDay()} ${d.getHours()}:${d.getMinutes()}`
  },
  RESET(state) {
    state.patients = Array.from(initialState.patients)
    state.FORM_CLASS = initialState.FORM_CLASS.toString() // immutability
    state.when = initialState.when.split('').join('') // immutability
    state.group = initialState.when.split('').join('')
  },
}

const actions = {

  setPatientsList({
    commit,

    // state,
    getters,
  }, patientListForm) {
    commit('SET_PATIENTS_LIST', patientListForm)
    commit('SET_DATA_TIME')
  },
  getNextPatient({
    commit,
    state,
    dispatch,
  }) {
    if (state.patients.length > -1) {
      const nextPatient = state.patients.slice().shift() // immutable
      const newPatientList = state.patients.filter(patient => patient !== nextPatient)

      commit('SET_PATIENTS_LIST', newPatientList)

      return nextPatient
    }

    return null
  },
  setFormClass({
    commit,
  }, FORM_CLASS) {
    commit('SET_FORM_CLASS', FORM_CLASS)
  },
  reset({
    commit,
  }) {
    commit('RESET')
  },
  setGroup({ commit }, selectedGroup) { commit('SET_GROUP', selectedGroup) },

}

const getters = {
  PatientGroupList(state) {
    return state.patients
  },
  PatientGroupFormClass(state) {
    return state.FORM_CLASS
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
