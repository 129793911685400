import moment from 'moment'

// map is the base stats of all integrations ( fillware, nexxus .. etc )
const initialState = {
  data: {},
  medications: [],
  when: '',
  selectedMedications: [],

  FORM_CLASS: '',
}

const state = { ...initialState }

const mutations = {
  SET_PATIENT_DATA(state, payload) {
    state.data = {
      ...state.data,
      ...payload,
    }
  },
  RESET_PATIENT_DATA(state) {
    state.data = { ...initialState.data }
    state.medications = Array.from(initialState.medications)
    state.selectedMedications = Array.from(initialState.selectedMedications)
    state.when = initialState.when.split('').join('') // immutability
  },
  SET_PATIENT_MEDICATIONS(state, payload) {
    state.medications = payload
  },
  SET_PATIENT_DATA_TIME(state, payload) {
    const d = new Date()
    state.when = `${d.getFullYear()}-${d.getMonth()}-${d.getDay()} ${d.getHours()}:${d.getMinutes()}`
  },
  SET_FORM_CLASS(state, payload) {
    state.FORM_CLASS = payload
  },
  UPDATE_PATIENT_DATA(state, payload) {
    state.data = payload
  },
  UPDATE_PATIENT_MEDICATIONS(state, patientData) {
    state.medications = patientData
  },
  UPDATE_PATIENT_DATA_TIME(state) {
    const d = new Date()
    state.when = `${d.getFullYear()}-${d.getMonth()}-${d.getDay()} ${d.getHours()}:${d.getMinutes()}`
  },
  SET_SELECTED_PATIENT_MEDICATIONS(state, payload) {
    state.selectedMedications = payload
  },

  // When user submits a form, clear meds
  CLEAR_SELECTED_PATIENT_MEDICATIONS(state) {
    state.selectedMedications = initialState.selectedMedications
  },
}

const actions = {
  async savePatientData({ commit, getters }, patientData) {
    let { data, medications } = getState(patientData)
    data = {
      ...data,
      medications,
    }
    if (!data['PHARMACY NAME']) {
      data['PHARMACY NAME'] = getters.PharmacyProfile.name || ''
    }

    if (!data['PHARMACY EMAIL ADDRESS']) {
      data['PHARMACY EMAIL ADDRESS'] = getters.PharmacyProfile.email || ''
    }

    if (!data['PHARMACY TELEPHONE NUMBER']) {
      data['PHARMACY TELEPHONE NUMBER'] = getters.PharmacyProfile.phone || ''
    }

    if (!data['PHARMACY FAX NUMBER']) {
      data['PHARMACY FAX NUMBER'] = getters.PharmacyProfile.fax || ''
    }

    if (data['DATE OF BIRTH'].indexOf('T') > -1) {
      data['DATE OF BIRTH'] = moment.utc(data['DATE OF BIRTH']).format('YYYY-MM-DD')
    }

    // if the DB don't have the address, we will use the internal getter
    if (!data['PHARMACY POSTAL CODE']) {
      data = {
        ...data,
        'PHARMACY UNIT NUMBER': getters.PharmacyProfile.address.unit || '',
        'PHARMACY STREET NUMBER': getters.PharmacyProfile.address.streetNumber || '',
        'PHARMACY STREET NAME': getters.PharmacyProfile.address.streetName || '',
        'PHARMACY CITY': getters.PharmacyProfile.address.city || '',
        'PHARMACY POSTAL CODE': getters.PharmacyProfile.address.code || '',
        'PHARMACY PROVINCE': getters.PharmacyProfile.address.state || '',
        'PHARMACY COUNTRY': getters.PharmacyProfile.address.country || '',
      }
    }
    commit('SET_PATIENT_DATA', data)
    commit('SET_PATIENT_MEDICATIONS', medications)
    commit('SET_PATIENT_DATA_TIME')

    return await new Promise(resolve => {
      resolve()
    })
  },
  updatePatientData({ commit }, payload) {
    commit('UPDATE_PATIENT_DATA', payload)
  },

  resetPatientData({ commit }) {
    commit('RESET_PATIENT_DATA')
  },

  setFormClass({ commit }, FORM_CLASS) {
    commit('SET_FORM_CLASS', FORM_CLASS)
  },
  setSelectedPatientMedications({ commit, state }, payload) {
    commit('SET_SELECTED_PATIENT_MEDICATIONS', payload)
  },
  clearSelectedPatientMedications({ commit }) {
    commit('CLEAR_SELECTED_PATIENT_MEDICATIONS')
  },
}

function getState(patientData) {
  const data = {
    ...patientData,
  }

  // clone array
  const medications = Array(data.medications).slice().pop()

  // clean up
  delete data.medications

  return {
    data,
    medications,
  }
}

const getters = {
  PatientProfile(state) {
    return state.data
  },
  PatientMedications(state) {
    return state.medications
  },
  FormClass(state) {
    return state.FORM_CLASS
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
