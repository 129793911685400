const shipping = [{
  path: '/ship/ship',
  name: 'ship-ship',
  component: () => import('@/views/ship/ship.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/ship/shiplist',
  name: 'ship-shiplist',
  component: () => import('@/views/ship/shiplist.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/ship/shipboard',
  name: 'ship-shipboard',
  component: () => import('@/views/ship/shipboard.vue'),
  meta: {
    layout: 'content',
  },
},
]

export default shipping
