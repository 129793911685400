import { pdfService } from '@/render/api/zarya/index'
import { submit as submitPdf } from '@/render/api/zenyatta/pdf'
import { fileDownload } from '@/util/functions/index'
import * as moment from 'moment'
import * as path from 'path'

const initialState = {
  pdf: {},
}

const state = { ...initialState }

const mutations = {
  ADD_PDF_DATA(state, pdfRef) {
    state.pdf = {
      ...state.pdf,
      [pdfRef.filename]: pdfRef,
    }
  },
  REMOVE_PDF_DATA(state, pdfRef) {
    // remove it if it exists
    if (state.pdf[pdfRef.filename]) {
      delete state.pdf[pdfRef.filename]
    }
  },
  SET_PDF_DATA(state, pdfsRef) {
    state.pdf = {
      ...state.pdf,
      ...pdfsRef,
    }
  },
  // eslint-disable-next-line no-unused-vars
  RESET_PDF_DATA(state) {
    state = { ...initialState }
  },
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  async pdfSubmitData({ commit, dispatch, state }, { formType, pdfData, filename }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { pdfUrl } = (await submitPdf(formType, pdfData)).data
      const pdfRef = {
        id: filename || '',
        filename: filename || '',

        // filename without extension
        key: path
          .basename(pdfUrl)
          .split('.')
          .slice(0, -1)
          .join('.'),
        pdfUrl,
      }
      await commit('ADD_PDF_DATA', pdfRef)

      // to allow registering to other store modules
      // await dispatch('pdfUpdateData', state.pdf);

      return pdfRef
    } catch (e) {
      throw e
    }
  },
  pdfDownload({ state }, {
    token, id, filename, pdfRef,
  }) {
    if (!pdfRef) {
      pdfRef = state.pdf[id]
    }

    // if no filename was provided, then use default
    if (!filename) {
      filename = pdfRef.filename
    }

    return pdfService.getPdf(pdfRef.key, token).then(blobData => {
      fileDownload(blobData, `${filename}-${moment().format('YYYY-MM-DD')}`.toLowerCase())
    })
  },
  setPdfData({ commit }, pdfsData) {
    return commit('SET_PDF_DATA', pdfsData)
  },
  resetPdfData({ commit }) {
    return commit('RESET_PDF_DATA')
  },
}

const getters = {
  PdfQueue(state) {
    return state.pdf
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
