import axios from '../http'
import config from './config'

const getAllDrugs = async accessToken => await axios.get(`${config.api}pgdrug`, {
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})
  .then(response => ((response.data)))

const getDrugByGeneric = async (generic, accessToken) => {
  try {
    const res = await axios.get(`${config.api}pgdrug/${generic}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

// const DeleteLogById = async (logId, accessToken) => {
//   try {
//     const res = await axios.delete(`${config.api}pharmacylogger/${logId}`, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     })

//     return res.data
//   } catch (e) {
//     throw new Error(e)
//   }
// }

// const getAllFridgeTrueLogs = async accessToken => await axios.get(`${config.api}pharmacylogger/allFridgeTrue`, {
//   headers: {
//     Authorization: `Bearer ${accessToken}`,
//   },
// })
//   .then(response => ((response.data)))

// const getAllCleaningTrueLogs = async accessToken => await axios.get(`${config.api}pharmacylogger/allCleaningTrue`, {
//   headers: {
//     Authorization: `Bearer ${accessToken}`,
//   },
// })
//   .then(response => ((response.data)))

// const getAllEquipmentTrueLogs = async accessToken => await axios.get(`${config.api}pharmacylogger/allEquipmentTrue`, {
//   headers: {
//     Authorization: `Bearer ${accessToken}`,
//   },
// })
//   .then(response => ((response.data)))

// const getAllQualityTrueLogs = async accessToken => await axios.get(`${config.api}pharmacylogger/allQualityTrue`, {
//   headers: {
//     Authorization: `Bearer ${accessToken}`,
//   },
// })
//   .then(response => ((response.data)))

// const createLog = async (accessToken, {
//   pharmacyID,
//   userID,
//   tab,
//   date,
//   time,
//   user,
//   currentTemp,
//   minTemp,
//   maxTemp,
//   units,
//   temprReset,
//   floors,
//   counters,
//   equipment,
//   scale,
//   methadone,
//   incident,
//   narcoticSales,
// }) => await axios.post(
//   `${config.api}pharmacylogger`, {
//     pharmacyID,
//     userID,
//     tab,
//     date,
//     time,
//     user,
//     currentTemp,
//     minTemp,
//     maxTemp,
//     units,
//     temprReset,
//     floors,
//     counters,
//     equipment,
//     scale,
//     methadone,
//     incident,
//     narcoticSales,
//   }, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//     },
//   },
// ).then(res => res.data)

export default {
  getDrugByGeneric,
  getAllDrugs,

  // getLogById,
  // getAllLog,
  // createLog,
  // getAllFridgeTrueLogs,
  // getAllCleaningTrueLogs,
  // getAllEquipmentTrueLogs,
  // getAllQualityTrueLogs,
  // DeleteLogById,
}
