import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import VueFormulate from '@braid/vue-formulate'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueHotkey from 'v-hotkey'
import Vue from 'vue'
import VueIntercom from 'vue-intercom'
import VueMoment from 'vue-moment'
import VueSignaturePad from 'vue-signature-pad'
import { audience, clientId, domain } from '../auth_config.json'
import App from './App.vue'
import './assets/tailwind.css'
import { Auth0Plugin } from './auth'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueSignaturePad)

Vue.use(PiniaVuePlugin)

const pinia = createPinia()

Vue.use(VueFormulate, {
    classes: {
    // outer: ({ isGrouped }) => (isGrouped ? 'tw-mb-1' : 'tw-mb-4'),

        outer: ({ classification, isGrouped }) => {
            switch (classification) {
            case 'text':
                return isGrouped ? 'tw-mb-1 tw-col-span-6 sm:tw-col-span-3 lg:tw-col-span-2' : 'tw-mb-4 '
            case 'box':
                return isGrouped
                    ? 'tw-mb-1 tw-flex tw-items-center'
                    : 'tw-mb-4 tw-col-span-6 sm:tw-col-span-3 tw-flex tw-items-center'
            case 'group':
                return isGrouped ? 'tw-mb-1' : 'tw-mb-4 tw-ml-8 tw-col-span-6 sm:tw-col-span-3'
            case 'select':
                return isGrouped ? 'tw-mb-1' : 'tw-mb-4  tw-col-span-6 sm:tw-col-span-3'
            default:
                return isGrouped ? 'tw-mb-1' : 'tw-mb-4'
            }
        },

        wrapper: ({ classification }) => {
            switch (classification) {
            case 'box':
                return 'tw-flex tw-items-center'
            default:
                return ''
            }
        },
        element: ({ classification, hasValue, type }) => {
            switch (classification) {
            case 'group':
                return 'tw-mt-2 tw-ml-8'
            case 'select':
                return !hasValue ? 'tw-text-gray-500 tw-font-light' : ''
            default:
                return type === 'textarea' ? 'tw-p-4' : ''
            }
        },
        input: ({ classification }) => {
            switch (classification) {
            case 'button':
                return 'tw-px-4 tw-py-2 tw-rounded tw-bg-blue-500 tw-text-white hover:tw-bg-blue-600'
            case 'group':
                return ''
            case 'box':
                return 'focus:tw-ring-indigo-500 tw-h-4 tw-w-4 tw-text-indigo-600 tw-border-gray-300'
            default:
                return 'tw-min-h-full tw-bg-blue-50 pa-2 tw-ml-3 focus:tw-outline-none focus:tw-ring-4 focus:tw-ring-pgblue focus:tw-ring-opacity-50 focus:tw-ring-pgblue focus:tw-border-pgblue tw-block tw-w-full tw-shadow sm:tw-text-sm tw-border-gray-300 tw-rounded-md dark:tw-bg-darkmodefieldbackground'
            }
        },
        decorator: ({ hasValue }) => {
            let base = 'tw-h-4 tw-w-4 tw-text-indigo-600 tw-border-gray-300'
            if (hasValue) {
                base += ' focus:tw-ring-indigo-500'
            }

            return base
        },
        label: 'tw-ml-3 tw-block tw-text-sm tw-font-medium tw-text-gray-700 dark:tw-text-white',
        help: 'tw-text-xs tw-mb-1 tw-text-gray-600',
        error: 'tw-text-red-700 tw-text-xs tw-mb-1',
    },
})

Vue.use(VueHotkey)

Vue.$root = {
    $system: {},
}

const moment = require('moment')

Vue.use(VueMoment, {
    moment: moment.utc,
})
Vue.moment = Vue.prototype.moment = moment.utc

Vue.config.productionTip = process.env.NODE_ENV === 'development'
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    useRefreshTokens: true,

    // cacheLocation: 'localstorage', // valid values are: 'memory' or 'localstorage'
    onRedirectCallback: appState => {
        router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
    },
})

// load Intercom
Vue.use(VueIntercom, { appId: 'klc1sn1m' })

if (process.env.NODE_ENV !== 'development') {
    // load sentry
    Sentry.init({
        Vue,
        dsn: 'https://5b1eeec9603e440eba83a447303cef98@o422586.ingest.sentry.io/5955290',
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['localhost', '*.pharmaguide.ca', /^\//],
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        release: process.env.VUE_APP_VERSION,
    })

    // load statusPage (for monitoring)
    const statusPageURL = 'https://pmklcs6zzcdh.statuspage.io/embed/script.js'
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', statusPageURL)
    document.body.appendChild(recaptchaScript)
}

new Vue({
    router,
    store,
    vuetify,
    pinia,
    render: h => h(App),
}).$mount('#app')
