/* eslint-disable eol-last */
/* eslint-disable import/prefer-default-export */

/**
 * It takes a date and a date format and returns a formatted date in the user's timezone.
 * @param date - The date you want to format
 * @param dateFormat - 'YYYY-MM-DD' default format if not specified
 * @returns A string of the date in the format specified.
 */

import moment from 'moment'

function momentTimeZone(date, dateFormat = 'YYYY-MM-DD') {
  const dateOffset = new Date().getTimezoneOffset()

  const formattedDate = moment(date).utcOffset(dateOffset).format(dateFormat)

  return formattedDate
}

export default momentTimeZone
