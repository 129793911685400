<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="300"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="8"
        offset-y="8"
        class="ms-4"
        dot
      >
        <v-avatar
          size="25px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img :src="$auth.user.picture"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img :src="$auth.user.picture"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ $store.state.User.user.firstName }} {{ $store.state.User.user.lastName }}
          </span>
          <small class="text--primary text-capitalize">{{ userProfile.ocp }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Connection -->
      <v-tooltip
        :key="componentKeyRefreshStatus"
        left
      >
        <template #activator="{ on, attrs }">
          <v-list-item
            v-bind="attrs"
            @click="resetpage"
            v-on="on"
          >
            <v-list-item-icon class="me-2">
              <v-icon
                v-if="isConnected"
                id="connectivity"
                size="22"
                color="success"
              >
                {{ icons.mdiWifi }}
              </v-icon>
              <v-icon
                v-if="!isConnected"
                id="connectivity"
                color="warning"
              >
                {{ icons.mdiWifiOff }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Refresh Connection</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span><b>Click Here to reset connection to Pharmacy</b></span>
      </v-tooltip>
      <!-- Profile -->
      <v-list-item to="/user/profile">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Team -->
      <v-list-item to="/user/team">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountMultiplePlus }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Team</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Support -->
      <v-list-item
        href="https://my.splashtop.com/sos/packages/download/WWZ5TW33AJ3A"
        target="_blank"
      >
        <v-list-item-icon class="me-2">
          <v-icon
            size="22"
            color="warning"
          >
            {{ icons.mdiRemoteDesktop }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Remote Support</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiWifi,
  mdiWifiOff,
  mdiAccountMultiplePlus,
  mdiRemoteDesktop,
} from '@mdi/js'

const crypto = require('crypto')

export default {
  data() {
    return {
      isConnected: true,
      isReconnecting: false,
      userProfile: this.$store.getters.UserProfile,
      componentKeyRefreshStatus: 0,
      gravatarSrc: '',
      hashedEmail: '',
      userEmail: this.$auth.user.email,
    }
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiWifi,
        mdiWifiOff,
        mdiAccountMultiplePlus,
        mdiRemoteDesktop,
      },
    }
  },
  async created() {},
  async mounted() {
    this.hashedEmail = this.hashEmail(this.userEmail)
  },
  methods: {
    resetpage() {
      this.$root.$emit('Refresh_Connection')

      //   location.reload()
    },
    forceRerender() {
      this.componentKeyRefreshStatus += 1
    },
    logout() {
      sessionStorage.clear()
      localStorage.clear()
      this.$auth.logout({
        returnTo: 'https://phox.pharmaguide.ca/login',
      })

      // this.$router.push('/login')
      // localStorage.removeItem('pharmacy')
      // localStorage.removeItem('messageQueueConfig')
      // localStorage.removeItem('serverConfig')
    },
    hashEmail(userEmail) {
      this.hashedEmail = crypto.createHash('md5').update(userEmail.trim().toLowerCase()).digest('hex')
      this.gravatarSrc = `https://www.gravatar.com/avatar/${this.hashedEmail}?s=200&d=retro`

      return this.hashedEmail
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
