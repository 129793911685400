// import store from '@/store'
// import praluentStore from '@/store/lazyLoaded/Praluent'

const patient = [
    {
        path: '/health-optimization/praluent/dashboard',
        name: 'health-optimization-praluent-dashboard',
        component: () => import('@/views/praluent/dashboard.vue'),
        beforeEnter(to, from, next) {
            // if (store.hasModule('praluent') === false) {
            //   store.registerModule('praluent', praluentStore, { preserveState: true })
            // }
            next()
        },
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/health-optimization/praluent/form',
        name: 'health-optimization-praluent-form',
        component: () => import('@/views/praluent/praluent.vue'),
        beforeEnter(to, from, next) {
            // FIXME: lazy load store
            // if (store.hasModule('praluent') === false) {
            //   store.registerModule('praluent', praluentStore, { preserveState: true })
            // }
            next()
        },
        meta: {
            layout: 'content',
        },
    },
]

export default patient
