import axios from '../http'
import config from './config'

const getRxByDate = async (accessToken, { pharmacyID, FillDate }) => {
  try {
    const res = await axios.post(
      `${config.api}prescription/all`,
      { pharmacyID, FillDate },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        'Content-Type': 'application/json',
      },
    )

    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

const getRxByPatientID = async (accessToken, { PatientID, pharmacyID }) => {
  try {
    const res = await axios.post(
      `${config.api}prescription/patId`,
      { pharmacyID, PatientID },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        'Content-Type': 'application/json',
      },
    )

    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

const DeleteLRxById = async (rxId, accessToken) => {
  try {
    const res = await axios.delete(`${config.api}prescription/${rxId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

const getAllRx = async (accessToken, pharmacyId) => await axios
  .get(`${config.api}prescription/all/${pharmacyId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  .then(response => response.data)

const getAllFridgeTrueLogs = async accessToken => await axios
  .get(`${config.api}prescription/allFridgeTrue`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  .then(response => response.data)

const getAllCleaningTrueLogs = async accessToken => await axios
  .get(`${config.api}prescription/allCleaningTrue`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  .then(response => response.data)

const getAllEquipmentTrueLogs = async accessToken => await axios
  .get(`${config.api}prescription/allEquipmentTrue`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  .then(response => response.data)

const getAllQualityTrueLogs = async accessToken => await axios
  .get(`${config.api}prescription/allQualityTrue`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  .then(response => response.data)

const createRxStatus = async (
  accessToken,
  {
    _id,
    pharmacyID,
    userID,
    FirstName,
    LastName,
    PatientName,
    ID,
    MixID,
    DocID,
    PatientID,
    Description,
    OrigRxNum,
    RxNum,
    FillDate,
    DispQty,
    AAC,
    Cost,
    Markup,
    Fee,
    MixTime,
    MixFee,
    Status,
    DocAddressLoc,
    DoctorFirstName,
    DoctorLastName,
    mixStatus,
    initials,
    notes,
    CompletedTime,
  },
) => await axios
  .post(
    `${config.api}prescription`,
    {
      _id,
      pharmacyID,
      userID,
      FirstName,
      LastName,
      PatientName,
      ID,
      MixID,
      DocID,
      PatientID,
      Description,
      OrigRxNum,
      RxNum,
      FillDate,
      DispQty,
      AAC,
      Cost,
      Markup,
      Fee,
      MixTime,
      MixFee,
      Status,
      DocAddressLoc,
      DoctorFirstName,
      DoctorLastName,
      mixStatus,
      initials,
      notes,
      CompletedTime,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
  .then(res => res.data)

const createRxStatusList = async (accessToken, rxList) => await axios
  .post(`${config.api}prescription/list`, rxList, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  .then(res => res.data)

export default {
  getRxByDate,
  getRxByPatientID,
  getAllRx,
  createRxStatus,
  createRxStatusList,
  getAllFridgeTrueLogs,
  getAllCleaningTrueLogs,
  getAllEquipmentTrueLogs,
  getAllQualityTrueLogs,
  DeleteLRxById,
}
