const groupHub = [
  {
    path: '/groupHub/groupForms',
    name: 'grouphub-groupforms',
    component: () => import('@/views/groupHub/groupForms.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/groupHub/batchHistory',
    name: 'grouphub-batchHistory',
    component: () => import('@/views/groupHub/batchHistory.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default groupHub
