const patient = [
  {
    path: '/patient/patientprofile',
    name: 'patient-profile',
    component: () => import('@/views/patient/PatientProfile.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default patient
