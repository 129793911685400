<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
    data-theme="light"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>
  </component>
</template>
<script>
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import { mapGetters, mapState } from 'vuex'

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// import { SystemConnection } from './services/integrations'

// Dynamic vh

export default {
  name: 'PhoxDocs',
  computed: {
    ...mapGetters(['PharmacyProfile', 'UserProfile']),
    ...mapState(['User']),
  },
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  data() {
    return {
      datatheme: '',
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0)
    },
  },
  created() {
    // this.setUserCredentials()
    this.initializeLayout()
  },

  //   async mounted() {},
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') {
        return `layout-content-${appContentLayoutNav.value}-nav`
      }

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
  methods: {
    dataTrigger() {
      if (this.appSkinVariant === 'default') {
        this.datatheme = 'light'
      } else {
        console.log(this.appSkinVariant)
      }
    },
    initializeLayout() {
      this.$store.dispatch('resetPatientData')
      this.$store.dispatch('resetState')
    },

    // async setSystem() {
    //   const accessToken = await this.$auth.getTokenSilently()
    //   this.$store.dispatch('getUser', accessToken).then(profile => {
    //     const $system = new SystemConnection(
    //       profile.pharmacy.serverConfig,
    //       profile.pharmacy.messageQueueConfig,
    //     )

    //     return $system.init().then(async () => {
    //       this.$root.$system = $system
    //       window.$system = $system
    //     })
    //   })
    // },
  },
}
</script>

<style>
@font-face {
  font-family: 'Robertson';
  src: local('Robertson'), url(./assets/fonts/Robertson.ttf) format('truetype');
}
</style>
