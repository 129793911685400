import axios from '../http';
import config from './config';


const getLogById = async (logId, accessToken) => {
    try {
        const res = await axios.get(`${config.api}pharmacylogger/${logId}`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },);
        return res.data;
    }catch(e){
        throw new Error(e);
    }
};

const DeleteLogById = async (logId, accessToken) => {
  try {
      const res = await axios.delete(`${config.api}pharmacylogger/${logId}`,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },);
      return res.data;
  }catch(e){
      throw new Error(e);
  }
};

const getAllLog = async (accessToken) => {
  return await axios.get(`${ config.api }pharmacylogger`,{
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  },)
  .then(function (response) {
    return ((response.data));
  })
};

const getAllFridgeTrueLogs = async (accessToken) => {
  return await axios.get(`${ config.api }pharmacylogger/allFridgeTrue`,{
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  },)
  .then(function (response) {
    return ((response.data));
  })
};

const getAllCleaningTrueLogs = async (accessToken) => {
  return await axios.get(`${ config.api }pharmacylogger/allCleaningTrue`,{
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  },)
  .then(function (response) {
    return ((response.data));
  })
};

const getAllEquipmentTrueLogs = async (accessToken) => {
  return await axios.get(`${ config.api }pharmacylogger/allEquipmentTrue`,{
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  },)
  .then(function (response) {
    return ((response.data));
  })
};

const getAllQualityTrueLogs = async (accessToken) => {
  return await axios.get(`${ config.api }pharmacylogger/allQualityTrue`,{
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  },)
  .then(function (response) {
    return ((response.data));
  })
};

const createLog = async (accessToken,{
  pharmacyID,
  userID,
  tab,
  date,
  time,
  user,
  currentTemp,
  minTemp,
  maxTemp,
  units,
  temprReset,
  floors,
  counters,
  equipment,
  scale,
  methadone,
  incident,
  narcoticSales
}) => {
    return await axios.post(
        `${ config.api }pharmacylogger`,{
          pharmacyID,
          userID,
          tab,
          date,
          time,
          user,
          currentTemp,
          minTemp,
          maxTemp,
          units,
          temprReset,
          floors,
          counters,
          equipment,
          scale,
          methadone,
          incident,
          narcoticSales
        },{
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      ).then(res => res.data);
};

export default {
    getLogById,
    getAllLog,
    createLog,
    getAllFridgeTrueLogs,
    getAllCleaningTrueLogs,
    getAllEquipmentTrueLogs,
    getAllQualityTrueLogs,
    DeleteLogById,
}
