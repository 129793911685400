class Insurance {
    constructor(id, name, planCode, code, carrierId) {
        let insurance = {
            id,
            name,
            planCode,
            code
        }
        if (carrierId){
            return {
                ...insurance,
                carrierId
            }
        }

        return insurance;
    }
}

const insuranceMap = {
    fillware: [
        // TODO this is specific to Repatha, more generic
        // Trillium
        new Insurance('odb','Ontario Drug Benefit', 'ODB'),
        // BLACK LISTED
        // new Insurance('','IONVI', 'ESI', '32'),
        //express scripts adjudicator

        new Insurance('praluentDesjardins','Desjardins', 'ESI', 'ESI12', '12'),
        new Insurance('praluentIndustrialAlliance', 'Industrial Alliances', 'ESI', 'ESI11', '11'),
        new Insurance('praluentManulife','Manulife', 'ESI','ESI02', '02'),
        new Insurance('praluentExpress', 'Express', 'ESI', 'ESI'),
        //assure adjudicator
        new Insurance('praluentGreatwestlife','GreatWestLife', 'AS','AS11', '11'),
        new Insurance('praluentSunlife', 'Sun Life Financial', 'AS', 'AS16','16'),
        new Insurance('praluentAssure', 'Assure', 'AS', 'AS'),
        //adjudicator=insurance company
        new Insurance('praluentGreenShield','GreenShield','GS', 'GS'),
        new Insurance('praluentSsq', 'SSQ','SSQ', 'SSQ'),
        new Insurance('praluentMedavieBlueCross', 'Medavie Blue Cross', 'BC', 'BC'),
        new Insurance('praluentNexgen', 'Nexgen','NEX', 'NEX'),
        new Insurance('praluentClaimsecure','Claimsecure', 'RXP','RXP' ),
        new Insurance('praluentLu', 'LU', 'LU', 'LU'),
        // list with planCode
        // the rest, we dont care about their carrierID for Fillware per business
        // new Insurance('mdm','MDM', 'MDM'),
    ],
    // FIXME: needs the mapping
    kroll: [
          // TODO this is specific to Repatha, more generic
        // Trillium
        new Insurance('odb','Ontario Drug Benefit', 'ODB'),
        // BLACK LISTED
        // new Insurance('','IONVI', 'ESI', '32'),
        //express scripts adjudicator

        new Insurance('praluentDesjardins','Desjardins', 'ESI', 'ESI12', '12'),
        new Insurance('praluentIndustrialAlliance', 'Industrial Alliances', 'ESI', 'ESI11', '11'),
        new Insurance('praluentManulife','Manulife', 'ESI','ESI02', '02'),
        new Insurance('praluentExpress', 'Express', 'ESI', 'ESI'),
        //assure adjudicator
        new Insurance('praluentGreatwestlife','GreatWestLife', 'AS','AS11', '11'),
        new Insurance('praluentSunlife', 'Sun Life Financial', 'AS', 'AS16','16'),
        new Insurance('praluentAssure', 'Assure', 'AS', 'AS'),
        //adjudicator=insurance company
        new Insurance('praluentGreenShield','GreenShield','GS', 'GS'),
        new Insurance('praluentSsq', 'SSQ','SSQ', 'SSQ'),
        new Insurance('praluentMedavieBlueCross', 'Medavie Blue Cross', 'BC', 'BC'),
        new Insurance('praluentNexgen', 'Nexgen','NEX', 'NEX'),
        new Insurance('praluentClaimsecure','Claimsecure', 'RXP','RXP' ),
        new Insurance('praluentLu', 'LU', 'LU', 'LU'),
        // list with planCode
        // the rest, we dont care about their carrierID for Fillware per business
        // new Insurance('mdm','MDM', 'MDM'),
    ],
    // FIXME: needs correct mapping
    nexxsys: [
        // TODO this is specific to Repatha, more generic
        // Trillium
        new Insurance('odb', 'Ontario Drug Benefit', 'DB'),
        // BLACK LISTED
        // new Insurance('','IONVI', 'ESI', '32'),
        //express scripts adjudicator

        new Insurance('praluentDesjardins', 'Desjardins', 'EP', 'ESI12', '12'),
        new Insurance('praluentIndustrialAlliance', 'Industrial Alliances', 'EP', 'ESI11', '11'),
        new Insurance('praluentManulife', 'Manulife', 'EP', 'ESI02', '02'),
        new Insurance('praluentExpress', 'Express Scripts', 'EP', 'ESI'),
        //assure adjudicator
        new Insurance('praluentGreatwestlife', 'Great West Life', 'AS', 'AS11', '11'),
        new Insurance('praluentSunlife', 'Sun Life Financial', 'AS', 'AS16', '16'),
        new Insurance('praluentAssure', 'Assure', 'AS', 'AS'),
        //adjudicator=insurance company
        new Insurance('praluentGreenShield', 'Green Shield', 'GS', 'GS'),
        new Insurance('praluentSsq', 'SSQ Financial Group', 'SQ', 'SSQ'),
        new Insurance('praluentMedavieBlueCross', 'Ontario Blue Cross', 'OB', 'BC'),
        new Insurance('praluentNexgen', 'NexgenRx', 'NX', 'NX'),
        new Insurance('praluentClaimsecure', 'ClaimSecure', 'CS', 'CS'),
        // new Insurance('praluentLu', 'LU', 'LU', 'LU'),
        // list with planCode
        // the rest, we dont care about their carrierID for Fillware per business
        // new Insurance('mdm','MDM', 'MDM'),
    ],
};


const initialState = {
    providers: []
};

const state = { ...initialState
};

const mutations = {
    SET_INSURANCE_MAP(state, insuranceProvidersMap) {
        state.providers = [...insuranceProvidersMap];
    },
    RESET_INSURANCE_MAP(state) {
        state.providers = [];
    }
};

const actions = {
    loadInsuranceProviders({
        rootGetters,
        commit
    }) {
        commit('SET_INSURANCE_MAP', insuranceMap[rootGetters.PharmacyProfile.DBType]);
    },
    getInsuranceProvider({
        state
    }, {
        carrierId,
        planCode
    }) {
        const insuranceProvider = state.providers.find((insuranceProvider) => {
            return insuranceProvider.planCode == planCode && insuranceProvider.carrierId == carrierId;
        });

        return insuranceProvider;
    }
}

export default {
    actions,
    mutations,
    state
};