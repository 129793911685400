import axios from 'axios'

const prod = process.env.NODE_ENV === 'production'

// const api = 'http://localhost/process.php'

// const api = 'https://pdfdev.pharmaguide.ca/process.php'

// const api = 'https://pdf.api.pharmaguide.ca/process.php'

// const api = 'https://pdftest.pharmaguide.ca/process.php'

const api = 'https://pdf.dev.pharmaguide.ca/process.php'

// const api = 'http://34.152.15.219/process.php'

// if (prod) {
// const api = 'https://pdf.pharmaguide.ca/process.php'

// }

export const submit = (formType, patientData) => axios.post(api, {
    FORM_CLASS: formType,
    ...patientData,
})
