const logger = [
  {
    path: '/logger/log',
    name: 'logger-log',
    component: () => import('@/views/logger/logger.vue'),
    meta: {
      layout: 'blank',
    },
  },
]

export default logger
