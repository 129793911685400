import axios from '../http'
import config from './config'

const requestLab = async ({
  userID, pharmacyID, patientID, attributes,
}) => await axios.post(
  `${config.api}lab/request`, {
    userID,
    patientID,
    pharmacyID,
    attributes,
    consent: true,
  },
).then(resp => resp.data)

const getLabByID = async labID => await axios.get(`${config.api}lab/${labID}`).then(resp => resp.data)

const getLabByPatientID = async patientID => await axios.get(`${config.api}lab/patient/${patientID}`).then(resp => resp.data)

const getLabByPatientAttributes = async ({ attributes, patientID, pharmacyID }) => await axios.post(`${config.api}lab/patient/${patientID}/attributes`, { attributes, patientID, pharmacyID })
  .then(resp => resp.data.pop())

const updateLabByID = async ({
  userID, pharmacyID, patientID, attributes, _id,
}) => await axios.put(
  `${config.api}lab/update`, {
    userID,
    patientID,
    pharmacyID,
    attributes,
    consent: true,
    _id,
    status: 'complete',
  },
).then(resp => resp.data)

export default {
  requestLab,
  getLabByID,
  getLabByPatientID,
  updateLabByID,
  getLabByPatientAttributes,
}
