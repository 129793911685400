/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
/* eslint-disable space-before-function-paren */
/* eslint-disable eol-last */
import { adminService } from '@/render/api/zarya'
import router from '@/router'
import { SystemConnection } from '@/services/integrations'
import store from '@/store'
import { getInstance } from './authWrapper'

export const homeGuard = (to, from, next) => {
  const authService = getInstance()

  const fn = async() => {
    // If the user is authenticated and NO pharmacy exists in sessionStorage, Get the user's pharmacies and set the first pharmacy as the active pharmacy and continue with the route
    if (authService.isAuthenticated && !sessionStorage.pharmacy) {
      const accessToken = await authService.getTokenSilently()
      store.dispatch('getUser', accessToken)
      const profile = await store.dispatch('getUser', accessToken)

      const $system = new SystemConnection(profile.pharmacy.serverConfig, {
        ...profile.pharmacy.messageQueueConfig,
        uniqueId: `docsRouter-${authService.user.email}`,
      })

      await $system.init()
      router.app.$root.$system = $system
      window.$system = $system

      return next()
    }

    // If the user is authenticated and a pharmacy exists in sessionStorage, continue with the route with current Pharmacy
    if (sessionStorage.pharmacy && authService.isAuthenticated) {
      const accessToken = await authService.getTokenSilently()
      store.dispatch('getUser', accessToken)
      const activePharmacyID = sessionStorage.pharmacy
      const selectedActivePharmacy = await adminService.getPharmacyByID(activePharmacyID, accessToken)
      await store.dispatch('getSelectedPharmacyInfo', selectedActivePharmacy)

      const $system = new SystemConnection(selectedActivePharmacy.serverConfig, {
        ...selectedActivePharmacy.messageQueueConfig,
        uniqueId: `docsRouter-${authService.user.email}`,
      })

      await $system.init()
      router.app.$root.$system = $system
      window.$system = $system

      // }

      // FIXME CHECK IF YOU NEED THESE EMITS
      // router.app.$root.$emit('Refresh_User_List', activePharmacyID)
      // delay until connection is established
      setTimeout(() => {
        router.app.$root.$emit('Refresh_Table')
      }, 1000)

      return next()
    }

    // authService.loginWithRedirect({
    //         appState: {
    //             targetUrl: '/home'
    //         }
    //     })
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}