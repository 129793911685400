const root = [{
  path: '/error-404',
  name: 'error-404',
  component: () => import('@/views/root/Error404.vue'),
  meta: {
    layout: 'blank',
    resource: 'Public',
  },
},
{
  path: '/login',
  name: 'auth-login',
  component: () => import('@/views/root/Login.vue'),
  meta: {
    layout: 'blank',
  },
},
]

export default root
