/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-debugger */
import * as CryptoJS from 'crypto-js';
import * as mqp from 'mqtt';
import * as RPC from 'mqtt-json-rpc';

/**
 * returns random number between the specified values
 * @param {int} min
 * @param {int} max
 * @returns random number between the specified values
 */

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min
}

// standard rabbitmq avaliable direct RPC queue
// const REPLY_QUEUE = 'amq.rabbitmq.reply-to';
// TODO: clean up thie queue
export class MQRpc {
    // internal connection variable
    conn = null;

    // to console this.log MQ
    DEBUG = false // process.env.NODE_ENV !== 'production';

    // enable encryption
    ENCRYPTION = true;

    // channel prefetch settings
    _channelPrefetch = 1;

    // queue creation options
    _queueOptions = {
        // durable: false,
        // auto_delete: true,
        // 'expires': 1000,
        // 'messageTtl': 1000
    };

    // queue sendToQueue and replyTo
    _queueSendOptions = {
        // expiration: 10000
    };

    // queue name space from rabbit MQ
    queueNameSpace;

    // connection config
    connConfig;

    // connection instance
    channel;

    // only auto connect first connection
    initialConnection = false;

    // variable for encryption specific for queue
    cryptr;

    // mqtt rpc instance
    rpc;

    // FIXME development environment
    constructor(mqConfig) {
        let clientId = `${Math.random(0, 1) * 10000}${Math.random(0, 1) * 10000}${Math.random(0, 1) * 10000}`
        if (mqConfig.uniqueId) {
            clientId = `${mqConfig.uniqueId}-${getRandomArbitrary(10000000, 99999999).toFixed(0)}`
        }

        if (mqConfig.hostname) {
            this.connConfig = {
                keepalive: 0,

                hostname: mqConfig.hostname,

                //   hostname: 'rabbit.pharmaguide.ca',
                protocol: mqConfig.protocol,
                path: mqConfig.path,

                //   port: mqConfig.port,
                //   protocol: 'mqtts',

                //   path: '/ws',
                //   hostname: '34.152.15.219',
                //   hostname: 'hubb.pharmaguide.ca',
                //   hostname: '35.203.19.181',
                //   hostname: 'clientpg.ngrok.io',
                //   hostname: '35.234.252.111',

                //   hostname: 'rpc.pharmaguide.ca',
                //   port: 15675,

                username: `${mqConfig.vhost}:${mqConfig.username}`,
                password: mqConfig.password,
                locale: 'en_US',

                // connectTimeout: parseInt(process.env.RMQ_RPC_TIMEOUT),
                connectTimeout: 10 * 60 * 1000, // 10 minutes

                // frameMax: 0,
                heartbeat: 0, // make sure its still connected amqp recommands not to
                // timeout: 10000,
                reconnectPeriod: 1000,

                // clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
                // clean: true,
                clientId,
                transformWsUrl: (url, options, client) => {
                    client.options.clientId = clientId

                    return url
                },

                // vhost: mqConfig.vhost
            }
        } else {
            this.connConfig = {
                protocol: 'mqtts',
                hostname: 'grand-otter.rmq.cloudamqp.com',
                path: '/ws/mqtt',
                keepalive: 0,
                username: `${mqConfig.vhost}:${mqConfig.username}`,
                password: mqConfig.password,
                locale: 'en_US',

                // connectTimeout: parseInt(process.env.RMQ_RPC_TIMEOUT),
                connectTimeout: 10 * 60 * 1000, // 10 minutes

                // frameMax: 0,
                heartbeat: 0, // make sure its still connected amqp recommands not to
                // timeout: 10000,
                reconnectPeriod: 1000,

                // clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
                // clean: true,
                clientId,
                transformWsUrl: (url, options, client) => {
                    client.options.clientId = clientId

                    return url
                },

                // vhost: mqConfig.vhost
            }
        }

        // FIXME better connection handling
        // this is to globalize the context of rmq
        window.$rmq = {
            reconnect: null,
            connected: false,
        }
        this.rpc = this.connect()
    }

    connect(reconnect = false) {
        console.log(this.initialConnection, this.conn, reconnect)

        // only allow initial connection or reconnection for MQ
        if (!this.initialConnection // first connection only
            || !this
                .conn // attempt reconnection if connection is null on wifi disconnection on client and pings are there
            || reconnect // force reconnection
        ) {
            this.initialConnection = true

            // console.log(this.channel)
            // nullify the channel too
            this.channel = null

            console.log({ connConfig: this.connConfig })
            const mqpConn = (this.rpc && this.rpc.reconnect()) || mqp.connect(this.connConfig)
            console.log(this.initialConnection)

            // console.log("mqpConn",mqpConn)
            // enable re-connection
            // FIXME test
            // conn.on('close', function () {
            //   console.error('Lost connection to RMQ.  Reconnecting in 5 seconds...');
            //   return setTimeout(this.connect, 5 * 1000);
            // });
            mqpConn.on('error', err => {
                this._reset()
                this.error(`MQ Error: connection error was thrown "${err}"`)

                // this.error('Lost connection to RMQ.  Reconnecting in 5 seconds...', err);
                // removed the auto reconnection
                //   setTimeout(window.$rmq.reconnect, 30 * 1000)
            })
            try {
                this.rpc = new RPC(mqpConn, {
                    timeout: this.connConfig.connectTimeout,
                })

                this.rpc.on('reconnect', e => {
                    this.info('reconnectiong', e)
                })
                this.rpc.on('error', e => {
                    this.info('Error', e)
                })
                this.rpc.on('close', e => {
                    this.info('Closed', e)
                })
                this.rpc.on('disconnect', e => {
                    this.info('disconnect', e)
                })
                this.rpc.on('end', e => {
                    this.info('end', e)
                })
                this.rpc.on('packetsend', e => {
                    this.info('packetsend', e)
                    localStorage.setItem('packetsend', JSON.stringify(e))
                })
                this.rpc.on('packetreceive', e => {
                    this.info('packetreceive', e)
                })
            } catch (err) {
                this.error(err)
                console.log('connect(reconnect = false) error', err)
            }

            // assign the reconnection
            window.$rmq.reconnect = this.rpc.reconnect

            return this.rpc
        }

        return this.rpc.on('connect', () => {
            Promise.resolve(this.rpc)
        })
    }

    log(msg, ...args) {
        if (this.DEBUG) {
            console.warn(msg, ...args)
        }
    }

    info(msg, ...args) {
        if (this.DEBUG) {
            console.info(msg, ...args)
        }
    }

    error(err, ...args) {
        console.error(err, ...args)
        console.log('error', err)
    }

    encrypt(msg, secretKey) {
        if (!this.ENCRYPTION) {
            return msg
        }

        return CryptoJS.AES.encrypt(msg, secretKey).toString()
    }

    decrypt(msg, secretKey) {
        if (!this.ENCRYPTION) {
            return msg
        }
        const msgDecrypt = CryptoJS.AES.decrypt(msg, secretKey)

        return msgDecrypt.toString(CryptoJS.enc.Utf8)
    }

    rpcClientFactory(queueNameSpace) {
        // set it
        this.queueNameSpace = queueNameSpace

        // return a generated function
        return this._rpcClient.bind(this)
    }

    async _rpcClient(requestParams, cb) {
        /*
                  this.channel.sendToQueue(
                      this.queueNameSpace,
                      Buffer.from(
                        this.encrypt(
                          JSON.stringify(
                            requestParams
                          )
                        )
                      ), {
                        ...this._queueSendOptions,
                        correlationId: corr,
                        replyTo: REPLY_QUEUE,
                      });
                  */

        return new Promise(resolve => {
        // console.log({msg: JSON.stringify(requestParams)});
            if (this.rpc.connected) {
                return this._rpcCall(requestParams, response => {
                    cb(response)
                    resolve(true)

                    // console.log("this.rpc.connected in queue diva")
                    // this.info(requestParams); (query)
                    // console.log(this.info(requestParams))
                })
            }
            this.rpc.reconnect()

            // console.log("reconnect in queue diva")
            return this._rpcCall(requestParams, response => {
                cb(response)
                resolve(true)
            })
        })
    }

    _rpcCall(requestParams, cb) {
        // this.log(`mRPC >> linked to ${this.queueNameSpace}`);
        // console.log({msg: JSON.stringify(requestParams)});
        return this.rpc.call(
            this.queueNameSpace, {
                msg: this.encrypt(JSON.stringify(requestParams), this.connConfig.username),
            },
        ).then(response => {
        /**
                                 const response = this.decrypt(msg.content.toString('utf8'));
                                    this.log(" [.] Got %s", response);
                                    // return the results
                                    channel.responseEmitter.emit(
                                      msg.properties.correlationId,
                                      response,
                                    );
                                  */
            const msgObjectStr = this.decrypt(response.toString('utf8'), this.connConfig.username)

            // this.log(" [.] Got %s", msgObjectStr, {requestParams});
            // FIXME: check what it does
            const msgObject = JSON.parse(msgObjectStr)
            cb(msgObject.msg)

        // closes connection
        // this.rpc.end();
        // console.log("new connection", cb)
        })
    }

    _reset() {
    //   setTimeout(window.$rmq.reconnect, 30 * 1000)
    //   this.connect()
    //   console.log('this.connect()')

        // trigger once
        if (window.$rmq.connected && this.conn) {
        // FIXME better connection handling
            window.$rmq.connected = false
            try {
                // trigger close if it is still open
                this.channel.close()
                this.conn.close()

                // console.log("its me")
            } catch (err) {
                this.error(err)
                console.log('_reset err', err)
            }
            this.channel = null
            this.conn = null
        }
    }
}
