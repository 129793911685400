import { MQRpc } from "../../render/api/diva/queue";
// import * as queriesModules from './fillware/queries';
// import * as ping from './fillware/queries/ping';
const QUERY_LIST_ENUM = [
    'DBQuery',
    'getBlisterPackMedications',
    'getDispensedLevelsJamp',
    // 'getDoctorName',
    'getInventoryLevelsJamp',
    'getMedscheckCandidates',
    'getPatientData',
    'getPraluentData',
    'getPatientMedicationHistory',
    // 'getPatientName',
    'getPrescriptionsToday',
    'getPrescriptionsTodayByNum',
    'getSearchByDoctor',
    'getSearchbyPharmacist',
    'getSearchByRx',
    'getSearchPatientsByName',
    'ping',
    'getMFRsList',
    'getGroupList',
    'getGroupPatientList',
    'getRecentRx',
    'getBlisterPackMedicationsGroup',
    'getSingleRecentRx',
    'getEncounterHistory',
    'getLuReport',
    'getSearchMd',
    'getOptimizeQuery',
    'getMixtures',
    'getMixtureDetails',
    'getMixtureByRx',
    'getMixturesBytPatient',
    // 'getPharmacyFacts',
    'getSingleRecentRxActiveOnly',
    'getPatientPlans',
    // 'getMixtureIngredients',
    // 'getMixturesList',
    // 'getMixturesPerPatient'
]
export class SystemConnection {
    // message queue connection
    _mqConn;
    // db adapter connection
    DBAdapterConn;
    // rabbit namespace
    mqRpcNamespace;
    dbConfig;
    constructor(dbConfig, mqConfig) {
            this.dbConfig = dbConfig;
            // establish queue connection
            this.mqConn = new MQRpc(mqConfig);
            // namespace of
            this.mqRpcNamespace = mqConfig.mqNamespace;
        }
        // init() {
        //     // promise it due to dynamic load modules
        //     // eslint-disable-next-line no-async-promise-executor
        //     return new Promise(async (resolve) => {
        //         await this.DBAdapterInit()
        //         // work around for first query error
        //         try {
        //             this.DBAdapterConn.query('');
        //         } catch (e) {
        //             console.log('DB has been initialize');
        //         }
        //         resolve();
        //     });
        // }
    async init() {
        // promise it due to dynamic load modules
        // eslint-disable-next-line no-async-promise-executor
        // return new Promise(async (resolve) => {
        await this.DBAdapterInit()
            // work around for first query error
            // try {
            //     this.DBAdapterConn.query('');
            // } catch (e) {
            //     console.log('DB has been initialize');
            // }
            // resolve();
            // });
    }
    async DBAdapterInit() {
        const dbType = this.dbConfig.databaseType;
        const CONFIG_SQL = {
            server: this.dbConfig.ip,
            port: parseInt(this.dbConfig.port),
            // database: this.dbConfig.database,
            user: this.dbConfig.username,
            password: this.dbConfig.password,
            options: {
                encrypt: true, // : false
                useUTC: true
            },
            pool: {
                max: 50,
                min: 0,
                idleTimeoutMillis: 30000,
            },
            requestTimeout: 0,
            connectionTimeout: 15000,
            // debug: true
        };
        // establish db path
        const dbPath = `./${dbType}`;
        // get back the adapter instance
        const DBAdapterFactory = await
        import (`${dbPath}/connect`);
        // get adapter connection instance
        this.DBAdapterConn = new DBAdapterFactory.DBAdapter(
            CONFIG_SQL,
            this.mqConn.rpcClientFactory(this.mqRpcNamespace),
        );

        QUERY_LIST_ENUM.forEach(async(queryModule) => {
            // FIXME: optimize to lazy load
            try {
                //DEBUG
                // console.log('queryModule loaded > ', queryModule)
                // console.log(dbType)
                const queryFunc = (await
                    import (`./${dbType}/queries/${queryModule}.js`)).default;
                this.DBAdapterConn[queryModule] = queryFunc.bind(this.DBAdapterConn);
            } catch (e) {
                throw new Error(`QueryList enum missing <${dbType}> module of: ${queryModule}"`);
            }
        })
    }
    RPCServer() {
        let rpcConnection;
        // TODO this is for server setup
        // register message queue's server to receive to handle RPC calls
        return {
            init: () => {
                this.mqConn.rpcServer(this.mqRpcNamespace, (queryString, attributes) => {
                    return new Promise((resolve, reject) => {
                            // resolve it and return results to MQ, non-tolerant connection
                            this.DBAdapterConn.query(queryString, attributes, false).then(
                                res => resolve(res),
                                err => reject(err)
                            );
                        })
                        // receive the connection
                }).then(rpcServerConn => {
                    console.info('received rpcServerConnection.');
                    rpcConnection = rpcServerConn;
                });
            },
            disconnect: () => {
                rpcConnection.close();
                console.info('closed rpcServerConnection.');
            }
        };
    }
    ping() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async(resolve, reject) => {
            try {
                // force disconnectivity
                setTimeout(() => {
                    reject('integrations index Ping: timed out');
                }, 2000);
                // dummy query to ping it
                await this.DBAdapterConn.ping();
                // console.log("resolove",this.DBAdapterConn.ping())
                resolve(true);
            } catch (err) {
                reject('Ping: failed to ping', err);
            }
        })
    }
}
// export function query(query, attr) {
//     return DBQuery(query, attr);
// }
// export function getPatientData(PatientID) {
//     return PatientData(PatientID);
// }
// export function getSearchPatientsByName(name) {
//     return SearchPatientsByName(name);
// }
// export function getPatientMedicationHistory(PatientID, GenericName = '', Strength = ''){
//     return PatientMedicationHistory(PatientID, GenericName, Strength);
// }
// export function getInventoryLevelsJamp(){
//     return InventoryLevelsJamp();
// }
// export function getDispensedLevelsJamp(date){
//     return DispensedLevelsJamp(date);
// }