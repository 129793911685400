import { patientService } from '../../render/api/zarya'

const state = {
  patientFiles: [],
  patientsTotal: 0,
  patientFile: {},
}

const mutations = {
  SET_PATIENTFILES(state, patientFiles) {
    state.patientFiles = patientFiles
  },
  CLEAR_PATIENTFILES(state) {
    state.patientFiles = [],
    state.patientsTotal = 0,
    state.patientFile = {}
  },
  SET_PATIENTFILE(state, patientFile) {
    state.patientFile = patientFile
  },

  // DEL_PATIENTFILE() {
  // },
}

const actions = {
  resetPatientProfileState({ commit }) {
    commit('CLEAR_PATIENTFILES')
  },

  async createPatientFile({ commit }, {
    token, _id, userID, pharmacyID, profile, meds, props,
  }) {
    return patientService.postPatientFile(token, _id, userID, pharmacyID, profile, meds, props).then(() => {
      patientService.getPatientFileById(token, _id, pharmacyID)
        .then(response => {
          commit('SET_PATIENTFILE', response)
        })
        .catch(error => {
          console.log('There was an error:', error.response)
        })
    })
  },

  fetchPatientFiles({ commit }, { token, pharmacyId }) {
    patientService.getPatientFiles(token, pharmacyId)
      .then(response => {
        commit('SET_PATIENTFILES', response)
        console.log(response)
      })
      .catch(error => {
        console.log('There was an error:', error.response)
      })
  },
  fetchPatientFile({ commit }, { token, id, pharmacyId }) {
    patientService.getPatientFileById(token, id, pharmacyId)
      .then(response => {
        commit('SET_PATIENTFILE', response)
      })
      .catch(error => {
        console.log('There was an error:', error.response)
      })
  },

  // deletePatientFile({ commit }, { token, id }) {
  //   patientService.DeletePatientById(token, id)
  //     .then(response => {
  //       commit('DEL_PATIENTFILE', response)
  //     })
  //     .catch(error => {
  //       console.log('There was an error:', error.response)
  //     })
  //   {
  //     patientService.getPatientFiles(token)
  //       .then(response => {
  //         commit('SET_PATIENTFILES', response)
  //         console.log(response)
  //       })
  //       .catch(error => {
  //         console.log('There was an error:', error.response)
  //       })
  //   }

  // }
  // },
}

const getters = {
  getPatientFileById: state => id => state.patientFiles.find(patientFile => patientFile._id === id),
  PatientProfileStoreGetter(state) {
    return state.patientFile[0]
  },
}

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
}
