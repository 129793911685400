const formHub = [{
  path: '/formhub',
  name: 'formhub',
  component: () => import('@/views/formHub/formHub.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/adapt',
  name: 'form-adapt',
  component: () => import('@/views/formHub/adapt.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/renew',
  name: 'form-renew',
  component: () => import('@/views/formHub/renew.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/opinion',
  name: 'form-opinion',
  component: () => import('@/views/formHub/opinion.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/bpgrid',
  name: 'form-bpgrid',
  component: () => import('@/views/formHub/bpgrid.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/verbalrx',
  name: 'form-verbalrx',
  component: () => import('@/views/formHub/verbalrx.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/fentanyl',
  name: 'form-fentanyl',
  component: () => import('@/views/formHub/fentanyl.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/vacation',
  name: 'form-vacation',
  component: () => import('@/views/formHub/vacation.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/frequentassessment',
  name: 'form-frequentassessment',
  component: () => import('@/views/formHub/frequentassessment.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/letter',
  name: 'form-letter',
  component: () => import('@/views/formHub/letter.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/rapidantigen',
  name: 'form-rapidantigen',
  component: () => import('@/views/formHub/rapidantigen.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/interactions',
  name: 'form-interactions',
  component: () => import('@/views/formHub/interactions.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/reassessment',
  name: 'form-reassessment',
  component: () => import('@/views/formHub/reassessment.vue'),
  meta: {
    layout: 'content',
  },
},
{
  path: '/formhub/template',
  name: 'form-template',
  component: () => import('@/views/formHub/template.vue'),
  meta: {
    layout: 'content',
  },
},
]

export default formHub
