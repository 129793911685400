import axios from '../http'
import config from './config'



const getUser = async (accessToken) => await axios.get(
  `${config.api}user`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  },
)

const getUserPharmacies = async ({ id }, token) => await axios.get(
  `${config.api}user/pharmacy`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      id,
    },
  },
).then(response => response.data)


// const addPoints = async (token, points,id ) =>  await axios.put(
//   `${config.api}user/addPoints?userId=${id}&points=${points}`, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   },
// )

const addPoints = async (token, points,id) => {
  try {
    const res = await axios.put(`${config.api}user/addPoints?userId=${id}&points=${points}`,{},{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  )
    return res.data
  } catch (e) {
    throw new Error(e)
  }
}



// const logout = async () =>
// // delete token
//   true

const amendUser = async (id,token, roleChange, firstName, lastName, ocp  ) => {
  try {
    return await axios.put(`${config.api}user/update`, {_id: id}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        roleChange,
        firstName,
        lastName,
        ocp
      },
    }
    ).then(response => response.data)
  } catch (e) {
    console.log(e)
  }
}

const deleteUser = async (pharmacy, user, token) => {
  try {
    await axios.delete(`${config.api}pharmacy/user`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { pharmacy, user },
      })
  } catch (e) {
    console.log(e)
  }
}

export default {
  // login,
  getUser,
  getUserPharmacies,
  // logout,
  amendUser,
  deleteUser,
  addPoints,
}
