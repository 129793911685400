import { mdiFormatListBulletedSquare, mdiMonitorDashboard, mdiTruck } from '@mdi/js'

export default [
  {
    title: 'Shipping',
    icon: mdiTruck,

    // badge: '3',
    // badgeColor: 'warning',
    children: [{
      title: 'Shipping',
      icon: mdiTruck,
      to: 'ship-ship',
    },
    {
      title: 'Shipping List',
      icon: mdiFormatListBulletedSquare,
      to: 'ship-shiplist',
    },
    {
      title: 'Shipping Dashboard',
      icon: mdiMonitorDashboard,
      to: 'ship-shipboard',
    },
    ],
  },
]
