const signatureCoordinates = [
    {
        name: "Assure",
        xValue: 280,
        yValue: 588,
        height: 40,
        width: 280,
        pageNo: 1,
        faxNumber: "+18668401509"
    },
    {
        name: "Claimsecure",
        xValue: 83,
        yValue: 512,
        height: 13,
        width: 160,
        pageNo: 1,
        faxNumber: "+19059493029"
    },
    {
        name: "ClaimSecure",
        xValue: 83,
        yValue: 512,
        height: 13,
        width: 160,
        pageNo: 1,
        faxNumber: "+19059493029"
    },
    {
        name: "Desjardins",
        xValue: 221,
        yValue: 414,
        height: 22,
        width: 140,
        pageNo: 1,
        faxNumber: "+18778382134"
    },
    {
        name: "Express",
        xValue: 54,
        yValue: 696,
        height: 29,
        width: 170,
        pageNo: 2,
        faxNumber: "+19057126329"
    },
    {
        name: "Express Scripts" ,
        xValue: 54,
        yValue: 696,
        height: 29,
        width: 170,
        pageNo: 2,
        faxNumber: "+19057126329"
    },
    {
        name: "GreatWestLife",
        xValue: 140,
        yValue: 445,
        height: 19,
        width: 138,
        pageNo: 1,
        faxNumber: "+12049467664"
    },
    {
        name: "Great West Life",
        xValue: 140,
        yValue: 445,
        height: 19,
        width: 138,
        pageNo: 1,
        faxNumber: "+12049467664"
    },
    {
        name: "GreenShield",
        xValue: 384,
        yValue: 313,
        height: 23,
        width: 177,
        pageNo: 3,
        faxNumber: "+15197396483"
    },
    {
        name: "Green Shield",
        xValue: 384,
        yValue: 313,
        height: 23,
        width: 177,
        pageNo: 3,
        faxNumber: "+15197396483"
    },
    {
        name: "Industrial Alliances",
        xValue: 135,
        yValue: 666,
        height: 19,
        width: 150,
        pageNo: 1,
        faxNumber: "+18777807247"
    },
    {
        name: "Manulife",
        xValue: 252,
        yValue: 335,
        height: 25,
        width: 177,
        pageNo: 7,
        faxNumber: "+18557520404"
    },
    {
        name: "BlueCross",
        xValue: 122,
        yValue: 671,
        height: 15,
        width: 180,
        pageNo: 2,
        faxNumber: "+18446612640"
    },
    {
        name: "Medavie Blue Cross",
        xValue: 122,
        yValue: 671,
        height: 15,
        width: 180,
        pageNo: 2,
        faxNumber: "+18446612640"
    },
    {
        name: "Nexgen",
        xValue: 165,
        yValue: 315,
        height: 23,
        width: 165,
        pageNo: 1,
        faxNumber: "+18776394369"
    },
    {
        name: "NexgenRx",
        xValue: 165,
        yValue: 315,
        height: 23,
        width: 165,
        pageNo: 1,
        faxNumber: "+18776394369"
    },
    {
        name: "SSQ",
        xValue: 35,
        yValue: 572,
        height: 25,
        width: 220,
        pageNo: 1,
        faxNumber: "+18554533942"
    },
    {
        name: "SSQ Financial Group",
        xValue: 35,
        yValue: 572,
        height: 25,
        width: 220,
        pageNo: 1,
        faxNumber: "+18554533942"
    },
    {
        name: "Sun Life Financial",
        xValue: 101,
        yValue: 500,
        height: 21,
        width: 172,
        pageNo: 2,
        faxNumber: "+18553429915"
    },
    // FIX ME: Fix below
    {
        name: "Ontario Drug Benefit",
        xValue: 100,
        yValue: 100,
        height: 100,
        width: 100,
        pageNo: 1,
        faxNumber: "+18553429915"
    }
];

export {signatureCoordinates};