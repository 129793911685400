import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import createLogger from 'vuex/dist/logger'
import app from './app'
import module from './modules'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage,
})

const debug = process.env.NODE_ENV === 'development'

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        ...module,
        appConfig: appConfigStoreModule,
        app,
    },
    plugins: (debug ? [createLogger()] : []).concat([vuexLocal.plugin])
})
