import {
  mdiAccountDetailsOutline,
  mdiAccountOutline,
  mdiFrequentlyAskedQuestions,
  mdiGift,
  mdiHomeGroup,
  mdiLightbulbOnOutline,
  mdiPill,
  mdiVideoVintage,
  mdiViewDashboardVariant,
} from '@mdi/js'
import healthOptimization from './healthOptimization'
import portals from './portals'
import shipping from './shipping'

export default [
  //   {
  //     title: 'Home',
  //     icon: mdiHome,
  //     to: 'home',
  //   },
  {
    subheader: 'Patient',
  },
  {
    title: 'Patient Profile',
    icon: mdiAccountOutline,
    to: 'patient-profile',
  },
  {
    title: 'Medscheck',
    icon: mdiPill,
    to: 'services-medscheck',
  },

  {
    subheader: 'Hubs',
  },
  {
    title: 'Form Hub',
    icon: mdiAccountDetailsOutline,
    to: 'formhub',
    badge: 'New',
    badgeColor: 'success',
  },
  {
    title: 'Dashboards',
    icon: mdiViewDashboardVariant,
    to: 'dashboard-hub',
    badge: 'New',
    badgeColor: 'success',
  },
  {
    title: 'Group Hub',
    icon: mdiHomeGroup,
    to: 'grouphub-groupforms',

    //   badge: 'Soon',
    //   badgeColor: 'info',
  },

  //   {
  //     title: 'Logger',
  //     icon: mdiPlaylistEdit,
  //     to: 'logger-log',
  //   },
  {
    subheader: 'Resources',
  },
  {
    title: 'Refer a Friend',
    icon: mdiGift,
    to: 'user-refer',
  },
  ...shipping,
  ...portals,
  ...healthOptimization,
  {
    title: 'Support',
    icon: mdiFrequentlyAskedQuestions,

    children: [
      {
        title: 'FAQ',
        icon: mdiFrequentlyAskedQuestions,
        to: 'support-faq',
      },
      {
        title: 'Support Videos',
        icon: mdiVideoVintage,
        to: 'support-video',
      },
      {
        title: 'Coming Soon',
        icon: mdiLightbulbOnOutline,
        to: 'support-comming-soon',
      },
    ],
  },
]
