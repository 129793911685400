<template>
  <div v-show="url">
    <v-tooltip
      color="success"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          :href="url"
          fab
          color="success"

          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            class="offset-1"
            color="white"
            small
          >
            {{ icons.mdiHelp }}
          </v-icon>
        </v-btn>
      </template>
      <span>Help</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mdiHelp } from '@mdi/js'
import { tours } from '@/assets/product-tours'

export default {
  name: 'Helpbutton',
  data() {
    return {
      url: '',
      tours,
      icons: {
        mdiHelp,
      },
    }
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.mapHelpTour()
      }
    },
  },
  mounted() {
    this.mapHelpTour()
  },
  methods: {
    mapHelpTour() {
      if (this.tours.find(({ name }) => name === this.$route.path)) {
        this.url = this.tours.find(({ name }) => name === this.$route.path).url
      } else {
        this.url = null
      }
    },
  },
}
</script>

<style scoped>
.v-btn--fab.v-size--default {
  height: 25px;
  width: 25px;
  /* margin-left: 16px; */
}
</style>
