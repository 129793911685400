import { userService } from '../../render/api/zarya/index'

const state = {
  pharmacy: {},
  user: {},

  // token: '',
}

const initState = {
  pharmacy: {},
  user: {},

  // token: '',
}

const mutations = {
  SET_USER(state, payload) {
    // state.pharmacy = payload.pharmacy
    state.user = payload.user

    // state.token = payload.access_token
  },
  SET_PHARMACY(state, payload) {
    state.pharmacy = payload
    sessionStorage.setItem('pharmacy', payload._id)
    localStorage.removeItem('selectedMfrList')

    // localStorage.setItem('pharmacy', payload._id)
    // localStorage.setItem('messageQueueConfig', JSON.stringify(payload.messageQueueConfig))
    // localStorage.setItem('serverConfig', JSON.stringify(payload.serverConfig))
  },
  SET_PHARMACIES(state, payload) {
    state.pharmacies = payload
  },
  RESET_USER(state) {
    state = { initState }

    // console.log("reset",state)
  },

}

const actions = {

  async getUser({ commit }, accessToken) {
    const user = await userService.getUser(accessToken).then(resp => resp.data)
    commit('SET_USER', JSON.parse(JSON.stringify(user)))

    return user
  },

  resetState({ commit }) {
    commit('RESET_USER')
  },

  async getSelectedPharmacyInfo({ commit }, activePharmacyID) {
    const pharmacy = activePharmacyID

    // console.log(pharmacy)
    commit('SET_PHARMACY', JSON.parse(JSON.stringify(pharmacy)))

    return pharmacy
  },
  async getUserPharmacies({ commit }, { id, token }) {
    const pharmacies = await userService
      .getUserPharmacies({
        id,
      },
      token).then(resp => resp)
    commit('SET_PHARMACIES', JSON.parse(JSON.stringify(pharmacies)))

    return pharmacies
  },

  // async getUserPharmacies({ commit }) {

  // async logoutUser({
  //     commit
  // }){
  //     // await commit('RESET_USER');
  //     // // remove token
  //     // delete this.$http.defaults.headers.common['Authorization'];
  // },
  // async resetUserProfile({
  //     commit
  // }) {
  //     // await commit('RESET_USER');
  // }
}

const getters = {
  UserProfile(state) {
    return state.user
  },
  PharmacyProfile(state) {
    let DBType = ''
    let MQHost = ''
    let phone = ''
    let fax = ''
    let address = {}
    let accountTier = ''

    if (state.pharmacy.serverConfig && state.pharmacy.messageQueueConfig) {
      DBType = state.pharmacy.serverConfig.databaseType
      MQHost = state.pharmacy.messageQueueConfig.vhost
    }

    if (state.pharmacy.address) {
      address = state.pharmacy.address
    }

    if (state.pharmacy.phone) {
      phone = state.pharmacy.phone
    }

    if (state.pharmacy.fax) {
      fax = state.pharmacy.fax
    }

    if (state.pharmacy.accountTier) {
      accountTier = state.pharmacy.accountTier
    }

    return {
      _id: state.pharmacy._id,
      name: state.pharmacy.name,
      email: state.pharmacy.email,
      accNumber: state.pharmacy.accNumber,
      phone,
      fax,
      address,
      DBType,
      MQHost,
      accountTier,
    }
  },

  // token(state) {
  //   return state.token
  // },
}

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
