<template>
  <v-dialog
    v-model="isDialogVisible"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template #activator="{ on: menu, attrs }">
      <v-tooltip
        bottom
        color="info"
      >
        <template #activator="{ on: tooltip }">
          <div data-intercom-target="FridgeLoggerButtonBlue">
            <v-btn
              fab
              color="error"
              dark
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              @click="refreshTime()"
            >
              <v-icon
                class="offset-1"
                color="white"
                small
              >
                {{ icons.mdiClipboardListOutline }}
              </v-icon>
            </v-btn>
          </div>
        </template>
        <span>Logger</span>
      </v-tooltip>
    </template>
    <v-card tile>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="initialState()"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title>Pharmacy Logger</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <div data-intercom-target="FridgeLoggerButton">
            <v-btn
              dark
              text
              class="my-auto"
              @click="payload()"
            >
              Save
            </v-btn>
          </div>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs
        :key="appBarLoggerKey"
        v-model="tab"
        grow
        centered
        icons-and-text
      >
        <v-tab>
          Daily Logs<v-icon>{{ icons.mdiCalendarToday }}</v-icon>
        </v-tab>
        <v-tab>
          Monthly Logs<v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
        </v-tab>
      </v-tabs>
      <!-- Dialy Logs Tab -->
      <!-- Time and user -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container fluid>
            <v-card-title
              primary-title
              class="justify-start"
            >
              <span class="text-h6 font-weight-bold">Daily Pharmacy Logs</span>
            </v-card-title>
            <v-card
              outlined
              elevation="2"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Date"
                          :prepend-icon="icons.mdiCalendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        color="primary"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menut"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="time"
                          label="Time"
                          :prepend-icon="icons.mdiClockTimeFourOutline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menut"
                        v-model="time"
                        color="primary"
                        full-width
                        @click:minute="$refs.menu.save(time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="user"
                      label="Username"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>

          <!-- Fridge Log -->
          <div data-intercom-target="FridgeLogger">
            <v-container fluid>
              <v-card-title
                primary-title
                class="justify-start"
              >
                <span class="text-h6 font-weight-bold">Fridge log</span>
              </v-card-title>
              <v-card
                outlined
                elevation="2"
              >
                <v-card-text id="fridgeLog">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-text-field
                        v-model="currentTemp"
                        label="Current Temp"
                        dense
                        persistent-hint
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="minTemp"
                        label="Minimum Temp"
                        dense
                        persistent-hint
                      ></v-text-field>
                      <v-text-field
                        v-model="maxTemp"
                        label="Maximum Temp"
                        dense
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-radio-group v-model="units">
                        <v-radio value="Fahrenheit">
                          <template v-slot:label>
                            <div><strong class="success--text">Fahrenheit (<sup>o</sup>F)</strong></div>
                          </template>
                        </v-radio>
                        <v-radio value="Celsius">
                          <template v-slot:label>
                            <div> <strong class="primary--text">Celsius (<sup>o</sup>C)</strong></div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                    >
                      <v-checkbox
                        v-model="temprReset"
                        :label="'Temp Max Reset: ' + `${temprReset.toString().toUpperCase()}`"
                        :on-icon="icons.mdiCheckCircle"
                        :off-icon="icons.mdiCloseCircle"
                        color="success"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </div>
          <!-- cleaning -->
          <v-container fluid>
            <v-card-title
              primary-title
              class="justify-start"
            >
              <span class="text-h6 font-weight-bold">Cleaning Log</span>
            </v-card-title>
            <v-card
              outlined
              elevation="2"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-checkbox
                      v-model="floors"
                      :label="'Pharmacy Floors Cleaned: ' + `${floors.toString().toUpperCase()}`"
                      :on-icon="icons.mdiCheckCircle"
                      :off-icon="icons.mdiCloseCircle"
                      color="success"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-checkbox
                      v-model="counters"
                      :label="'Pharmacy Counters Cleaned: ' + `${counters.toString().toUpperCase()}`"
                      :on-icon="icons.mdiCheckCircle"
                      :off-icon="icons.mdiCloseCircle"
                      color="success"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-checkbox
                      v-model="equipment"
                      :label="'Pharmacy Equipment Cleaned: ' + `${equipment.toString().toUpperCase()}`"
                      :on-icon="icons.mdiCheckCircle"
                      :off-icon="icons.mdiCloseCircle"
                      color="success"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-tab-item>

        <!-- Montly Tabs Items -->
        <v-tab-item>
          <v-container fluid>
            <v-card-title
              primary-title
              class="justify-start"
            >
              <span class="text-h6 font-weight-bold">Monthly Pharmacy Logs</span>
            </v-card-title>
            <v-card
              outlined
              elevation="2"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Date"
                          :prepend-icon="icons.mdiCalendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        color="primary"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menut"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="time"
                          label="Time"
                          :prepend-icon="icons.mdiClockTimeFourOutline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menut"
                        v-model="time"
                        color="primary"
                        full-width
                        @click:minute="$refs.menu.save(time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      v-model="user"
                      label="Username"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>

          <!-- Fridge Log -->
          <v-container fluid>
            <v-card-title
              primary-title
              class="justify-start"
            >
              <span class="text-h6 font-weight-bold">Equipment Calibration</span>
            </v-card-title>
            <v-card
              outlined
              elevation="2"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-checkbox
                      v-model="scale"
                      :label="'Scale Calibrated: ' + `${scale.toString().toUpperCase()}`"
                      :on-icon="icons.mdiCheckCircle"
                      :off-icon="icons.mdiCloseCircle"
                      color="success"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-checkbox
                      v-model="methadone"
                      :label="'Methadone Dispensing pump Calibrated: ' + `${methadone.toString().toUpperCase()}`"
                      :on-icon="icons.mdiCheckCircle"
                      :off-icon="icons.mdiCloseCircle"
                      color="success"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>

          <!-- cleaning -->
          <v-container fluid>
            <v-card-title
              primary-title
              class="justify-start"
            >
              <span class="text-h6 font-weight-bold">Quality Assurance</span>
            </v-card-title>
            <v-card
              outlined
              elevation="2"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-checkbox
                      v-model="incident"
                      :label="'Incident and Near Miss Reports Reviewed: ' + `${incident.toString().toUpperCase()}`"
                      :on-icon="icons.mdiCheckCircle"
                      :off-icon="icons.mdiCloseCircle"
                      color="success"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-checkbox
                      v-model="narcoticSales"
                      :label="'Narcotic Sales Records Reviewed: ' + `${narcoticSales.toString().toUpperCase()}`"
                      :on-icon="icons.mdiCheckCircle"
                      :off-icon="icons.mdiCloseCircle"
                      color="success"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
        </v-tab-item>
        <!--
            <v-row>
                <v-col cols="12" sm="6">
                    <v-select :items="['0-17', '18-29', '30-54', '54+']" label="Age" dense></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-autocomplete :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']" label="Interests" dense multiple></v-autocomplete>
                </v-col>
            </v-row> -->
      </v-tabs-items>
      <v-card-actions class="justify-center">
        <v-btn
          large
          color="error"
          outlined
          @click="initialState()"
        >
          Close <v-icon
            dark
            left
          >
            {{ icons.mdiMinusCircleOutline }}
          </v-icon>
        </v-btn>

        <v-btn
          large
          color="success"
          @click="payload()"
        >
          Save <v-icon
            dark
            right
          >
            {{ icons.mdiCheckboxMarkedCircleOutline }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="successSave"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-alert
          border="left"
          elevation="5"
          type="success"
        >
          Log Saved
        </v-alert>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import {
  mdiClipboardListOutline,
  mdiCalendarClockOutline,
  mdiCalendar,
  mdiClockTimeFourOutline,
  mdiCheck,
  mdiClose,
  mdiAlarm,
  mdiAlarmCheck,
  mdiCheckCircle,
  mdiCloseCircle,
  mdiCheckboxMarkedCircleOutline,
  mdiMinusCircleOutline,
  mdiCalendarMonth,
  mdiCalendarToday,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// import DateTimeLogger from '@components/common/ui/Form/Datepicker/DateTimeLogger'
import {
  required,
  emailValidator,
  passwordValidator,
  between,
  integerValidator,
  regexValidator,
  alphaValidator,
  urlValidator,
  confirmedValidator,
  lengthValidator,
  alphaDashValidator,
} from '@core/utils/validation'
import { pharmacyloggerService } from '../../render/api/zarya'

export default {
  components: {
    // datetime: Datetime,
  },
  setup() {
    const tab = ref('')
    const narcoticSales = ref(false)
    const incident = ref(false)
    const temprReset = ref(false)
    const floors = ref(false)
    const methadone = ref(false)
    const scale = ref(false)
    const counters = ref(false)
    const equipment = ref(false)

    // validate
    const form = ref(null)

    //

    const isDialogVisible = ref(false)
    const date = new Date().toISOString().substr(0, 10)
    const menu = ref(false)
    const modal = ref(false)
    const menu2 = ref(false)
    const menuref = ref(null)
    const time = `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`
    const menut = ref(false)
    const modal2 = ref(false)

    return {
      narcoticSales,
      incident,
      floors,
      scale,
      methadone,
      counters,
      equipment,
      temprReset,
      tab,
      isDialogVisible,
      date,
      menu,
      modal,
      menu2,
      menuref,
      time,
      menut,
      modal2,
      icons: {
        mdiClipboardListOutline,
        mdiCalendarClockOutline,
        mdiCalendar,
        mdiClockTimeFourOutline,
        mdiCheck,
        mdiClose,
        mdiAlarm,
        mdiAlarmCheck,
        mdiCheckCircle,
        mdiCloseCircle,
        mdiCheckboxMarkedCircleOutline,
        mdiMinusCircleOutline,
        mdiCalendarToday,
        mdiCalendarMonth,
      },
      required,
      emailValidator,
      passwordValidator,
      between,
      integerValidator,
      regexValidator,
      alphaValidator,
      urlValidator,
      confirmedValidator,
      lengthValidator,
      alphaDashValidator,
    }
  },
  computed: {
    ...mapGetters(['PharmacyProfile', 'UserProfile']),
    ...mapState(['User']),
  },
  data() {
    return {
      appBarLoggerKey: 0,
      successSave: false,
      userPharmacies: '',
      userProfile: this.$store.getters.UserProfile,
      currentTemp: '',
      minTemp: '',
      maxTemp: '',
      units: 'Celsius',
      user: this.$auth.user.name,
    }
  },
  mounted() {
    this.initialState()

    // console.log('initialState() ', this.initialState())
  },
  methods: {
    forceRerender() {
      this.appBarLoggerKey += 1
    },
    refreshTime() {
      this.date = this.currentDate()
      this.time = this.currentTime()
    },
    initialState() {
      this.isDialogVisible = false
      this.pharmacyID = this.$store.getters.PharmacyProfile._id
      this.userID = this.$store.getters.UserProfile._id
      this.tab = ''
      this.date = this.currentDate()
      this.time = this.currentTime()
      this.user = this.$auth.user.name
      this.currentTemp = ''
      this.minTemp = ''
      this.maxTemp = ''
      this.units = 'Celsius'
      this.temprReset = false
      this.floors = false
      this.counters = false
      this.equipment = false
      this.scale = false
      this.methadone = false
      this.incident = false
      this.narcoticSales = false
    },
    currentDate() {
      const date = new Date().toISOString().substr(0, 10)

      return date
    },
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`

      return dateTime
    },
    currentTime() {
      const current = new Date()
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`

      return time
    },

    async payload() {
      const token = await this.$auth.getTokenSilently()
      pharmacyloggerService.createLog(token, {
        pharmacyID: this.$store.getters.PharmacyProfile._id,
        userID: this.$store.getters.UserProfile._id,
        tab: this.tab,
        date: this.date,
        time: this.time,
        user: this.user,
        currentTemp: this.currentTemp,
        minTemp: this.minTemp,
        maxTemp: this.maxTemp,
        units: this.units,
        temprReset: this.temprReset,
        floors: this.floors,
        counters: this.counters,
        equipment: this.equipment,
        scale: this.scale,
        methadone: this.methadone,
        incident: this.incident,
        narcoticSales: this.narcoticSales,
      })
      this.isDialogVisible = false
      await this.$root.$emit('Refresh_Log', this.tab)
      this.successSave = true
      window.setTimeout(() => {
        this.successSave = false
      }, 2000)
      this.initialState()
      await this.forceRerender()
    },
  },
}
</script>

<style scoped>
.v-btn--fab.v-size--default {
  height: 25px;
  width: 25px;
  margin-left: 16px;
}
</style>
