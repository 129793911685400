const insuranceFormNames = [
    {
      formName: "praluentDesjardins",
      code: "ESI12",
      insuranceCompany: "Desjardins",
    },
    {
      formName: "praluentExpress",
      code: "ESI",
      insuranceCompany: "Express",
    },
    {
      formName: "praluentExpress",
      code: "EP",
      insuranceCompany: "Express",
    },
    {
      formName: "praluentGreatwestlife",
      code: "AS11",
      insuranceCompany: "GreatWestLife",
    },
    {
      formName: "praluentGreenShield",
      code: "GS",
      insuranceCompany: "GreenShield",
    },
    { formName: "praluentSsq", code: "SSQ", insuranceCompany: "SSQ" },
    { formName: "praluentSsq", code: "SQ", insuranceCompany: "SSQ" },
    {
      formName: "praluentManulife",
      code: "ESI02",
      insuranceCompany: "Manulife",
    },
    {
      formName: "praluentManulife",
      code: "EP02",
      insuranceCompany: "Manulife",
    },
    { formName: "praluentAssure", code: "AS", insuranceCompany: "Assure" },
    {
      formName: "praluentSunlife",
      code: "AS16",
      insuranceCompany: "Sunlife",
    },
    {
      formName: "praluentMedavieBlueCross",
      code: "BC",
      insuranceCompany: "BlueCross",
    },
    {
      formName: "praluentMedavieBlueCross",
      code: "OB",
      insuranceCompany: "BlueCross",
    },
    { formName: "praluentNexgen", code: "NEX", insuranceCompany: "Nexgen" },
    { formName: "praluentNexgen", code: "NX", insuranceCompany: "Nexgen" },
    {
      formName: "praluentIndustrialAlliance",
      code: "ESI11",
      insuranceCompany: "IndustrialAlliance",
    },
    {
      formName: "praluentClaimsecure",
      code: "RXP",
      insuranceCompany: "ClaimSecure",
    },
    {
      formName: "praluentClaimsecure",
      code: "CS",
      insuranceCompany: "ClaimSecure",
    },
    { 
      formName: "praluentLu", 
      code: "LU", 
      insuranceCompany: "LU" 
    },
  ]

  export { insuranceFormNames }