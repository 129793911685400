/**
 * returns random number between the specified values
 * @param {data}
 * @param {filename}
 * @param {mime}
 * @returns random number between the specified values
 */

function fileDownload(data, filename, mime, ext = 'pdf') {
  const blob = new Blob([data], {
    type: mime || 'application/octet-stream',
  })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, `${filename}.${ext}`)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', `${filename}.${ext}`)
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}

export default fileDownload
