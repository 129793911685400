const services = [{
  path: '/services/medscheck',
  name: 'services-medscheck',
  component: () => import('@/views/Medscheck/Medscheck.vue'),
  meta: {
    layout: 'content',
  },
},

]

export default services
