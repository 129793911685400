import axios from '../http'
import config from './config'

const addNewUser = async (body,accessToken) => {
  try {
    return await axios.put(
      `${config.api}admin/pharmacy/user`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(response => response.data)
  } catch (e) {
    console.log(e)
  }
}

const getPharmacyByID = async (stateId, accessToken) => {
  try {
    return await axios.get(
      `${config.api}admin/pharmacy/update/${stateId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    ).then(response => response.data)
  } catch (e) {
    console.log(e)
  }
}
export default {
  addNewUser,
  getPharmacyByID
}
