import { trackService } from '@/render/api/zarya/index'

// FIXME set it from outside class
import { insuranceFormNames } from '@/views/praluent/data/insuranceFormNames'
import { signatureCoordinates } from '@/views/praluent/data/signatureCoordinates'

const initialState = {
    praluentPatient: {},
    trackID: null,
    patientID: null,
    formClass: '',
    status: null,
    payload: {
    // FIXME remove this body, it will automatically be generated
        startedDateTime: '',
        patientConsent: false, // step 1
        pharmacyAgreement: false, // step 1
        labID: null,
        labRequested: false, // step 1
        labRequestedDateTime: '',

        // questionnaire: false, // step 2
        // questionnaireDateTime: '', // step 2
        // trillium: false, // step 3
        // trilliumDateTime: '', // step 3
        // eligibility: false, // step 4
        // eligibilityDateTime: '', // step 4
        // coverage: false, // step 5
        // coverageDateTime: '', // step 5
        emailToPatient: false,
        timeSent: '',
        documentId: null,
        signedByPatient: false,
        timeSignedByPatient: '',
        faxedToDoctor: false,
        timefaxedToDoctor: '',
        signedByDoctor: false,
        sentToInsurance: false,
        insuranceResult: null,
        pdfReferences: {},
    },
    meta: {},
}

// FIXME set it from outside class
const steps = [
    {
    // FIXME pdfId was a hack since this is in-consistent
        id: 'startedDateTime',
        pdfId: 'medication-history',
        submissionID: '',
        label: 'Medication History',
        done: false,
    },
    {
        id: 'labID',
        submissionID: '',
        label: 'Input Lab Data',
        done: false,
    },

    // {
    //     id: 'questionnaire' ,
    //     submissionID: '',
    //     pdfId: 'simon-diagnostic-criteria',
    //     label: 'Questionnaire',
    //     done: false,
    // },
    // {
    //     id: 'eligibility',
    //     submissionID: '',
    //     pdfId: 'eligibility-criteria',
    //     label: 'LU Eligibility',
    //     // FIXME in the future doctors will get sms
    //     // signedDate: '',
    //     done: false,
    // },
    // {
    //     id: 'trillium' ,
    //     submissionID: '',
    //     pdfId: 'trillium',
    //     label: 'Trillium',
    //     signedDate: '',
    //     done: false,
    // },
    {
        id: 'coverage',
        submissionID: '',
        pdfId: 'insurance-provider',
        label: 'Private Coverage',
        signedDate: '',
        done: false,
    },
]

// GENERIC track store

const state = { ...initialState }

const mutations = {
    SET_PATIENT_DATA_V2(state, payload) {
        state.praluentPatient = {
            ...state.praluentPatient,
            ...payload,
        }
    },
    RESET_PATIENT_DATA_V2(state) {
        state.praluentPatient = { ...initialState.praluentPatient }
        state.medications = Array.from(initialState.medications)
        state.when = initialState.when.split('').join('') // immutability
    },
    SET_PATIENT_MEDICATIONS_V2(state, payload) {
        state.medications = payload
    },
    RESET_PRALUENT_STATE(state, data) {
        Object.assign(state, { ...initialState })
    },
    SET_PRALUENT_REMOVE_ARRAY_INDEX(state, data) {
        state.praluentPatient[data.arrayName].splice(data.statinIndex, 1)
    },

    SET_PRALUENT_ADD_ARRAY_INDEX(state, data) {
        state.praluentPatient[data.arrayName].push(data.template)
    },

    SET_PRALUENT_ARRAY(state, data) {
        state.praluentPatient[data.arrayName][data.index] = {
            ...state.praluentPatient[data.arrayName][data.index],
            ...{
                [data.key]: data.value,
            },
        }
    },
    SET_PRALUENT_SINGLE_STATE(state, data) {
        state.praluentPatient = {
            ...state.praluentPatient,
            ...{
                [data.key]: data.value,
            },
        }
    },
    SET_PRALUENT_OVERLAY_DATA(state, data) {
        state.praluentPatient = {
            ...state.praluentPatient,
            ...data,
        }
    },
    SET_PRALUENT_PATIENT_WHOLE(state, data) {
        state.praluentPatient = data
    },
    SET_PRALUENT_PREFILL_DATA(state, data) {
        state.praluentPatient = {
            ...state.praluentPatient,
            ...data,
        }
    },
    SET_PROCESS(state, data) {
        if (data.trackID) {
            state.trackID = data.trackID
        }

        if (data.patientID) {
            state.patientID = data.patientID
        }

        if (data.payload) {
            state.payload = {
                ...state.payload,
                ...data.payload,
            }
        }
    },
    SET_META_DATA(state, metaData) {
        state.meta = {
            ...state.meta,
            ...metaData,
        }
    },
    SET_FORM_CLASS(state, formClass) {
        state.formClass = formClass
    },
    SET_STATUS(state, status) {
        state.status = status
    },
    RESET_PROCESS(state) {
        Object.assign(state, initialState)
    },
}

const actions = {
    async savePatientDataV2(
        {
            commit,

            // state,
            getters,
        },
        patientData,
    ) {
        commit('SET_PATIENT_DATA_V2', patientData.praluentPatient)

        return await new Promise(resolve => {
            resolve()
        })
    },
    async getProcess({ rootGetters, commit, state }, args) {
        let patientID = false
        const { trackID } = rootGetters.TrackProcess
        if (args) {
            patientID = args.patientID
        }

        // const formClass = "amgenRepatha";
        let track
        const token = await this._vm.$auth.getTokenSilently()
        try {
            if (trackID) {
                track = await trackService
                    .getByPatient(
                        {
                            patientID: patientID || rootGetters.PatientProfile.PatientID,
                            pharmacyID: rootGetters.PharmacyProfile._id,
                            formClass: rootGetters.FormClass,
                            trackId: trackID,
                        },
                        token,
                    )
                    .then(resp => resp.data)
            } else {
                track = await trackService
                    .create(
                        {
                            patientID: patientID || rootGetters.PatientProfile.PatientID,
                            pharmacyID: rootGetters.PharmacyProfile._id,
                            payload: state.payload,
                            formClass: rootGetters.FormClass,
                            patientFirstName: rootGetters.PatientProfile['FIRST NAME'],
                            patientLastName: rootGetters.PatientProfile['LAST NAME'],
                            consent: true,
                        },
                        token,
                    )
                    .then(resp => resp.data)
            }
        } catch (e) {
            if (!track) {
                track = await trackService
                    .create(
                        {
                            patientID: patientID || rootGetters.PatientProfile.PatientID,
                            pharmacyID: rootGetters.PharmacyProfile._id,
                            payload: state.payload,
                            formClass: rootGetters.FormClass,
                            patientFirstName: rootGetters.PatientProfile['FIRST NAME'],
                            patientLastName: rootGetters.PatientProfile['LAST NAME'],
                            consent: true,
                        },
                        token,
                    )
                    .then(resp => resp.data)
            }
        }
        await commit('SET_PROCESS', {
            trackID: track._id,
            patientID: patientID || track.patientID,
            payload: track.payload,
            formClass: rootGetters.FormClass,
        })

        await commit('SET_STATUS', track.status)

        await commit('SET_META_DATA', track.meta)

        // set form class
        // no need since thats for the pdf
        // await dispatch('SET_FORM_CLASS', formClass);

        return state
    },

    // FOR PRAUENT V2 -- getProcessV2
    async getProcessV2({ rootGetters, commit, state }, args) {
        let patientID = false
        const { trackID } = rootGetters.TrackProcess
        if (args) {
            patientID = args.patientID
        }

        // const formClass = "amgenRepatha";
        let track
        const token = await this._vm.$auth.getTokenSilently()
        try {
            if (trackID) {
                track = await trackService
                    .getByPatient(
                        {
                            patientID: patientID || rootGetters.TrackProcess.praluentPatient.PatientID,
                            pharmacyID: rootGetters.PharmacyProfile._id,
                            formClass: rootGetters.FormClass,
                            trackId: trackID,
                        },
                        token,
                    )
                    .then(resp => resp.data)
            } else {
                track = await trackService
                    .create(
                        {
                            patientID: patientID || rootGetters.TrackProcess.praluentPatient.PatientID,
                            pharmacyID: rootGetters.PharmacyProfile._id,
                            payload: state.payload,
                            formClass: rootGetters.FormClass,
                            patientFirstName: rootGetters.TrackProcess.praluentPatient['FIRST NAME'],
                            patientLastName: rootGetters.TrackProcess.praluentPatient['LAST NAME'],
                            consent: true,
                        },
                        token,
                    )
                    .then(resp => resp.data)
            }
        } catch (e) {
            if (!track) {
                track = await trackService
                    .create(
                        {
                            patientID: patientID || rootGetters.TrackProcess.praluentPatient.PatientID,
                            pharmacyID: rootGetters.PharmacyProfile._id,
                            payload: state.payload,
                            formClass: rootGetters.FormClass,
                            patientFirstName: rootGetters.TrackProcess.praluentPatient['FIRST NAME'],
                            patientLastName: rootGetters.TrackProcess.praluentPatient['LAST NAME'],
                            consent: true,
                        },
                        token,
                    )
                    .then(resp => resp.data)
            }
        }
        await commit('SET_PROCESS', {
            trackID: track._id,
            patientID: patientID || track.patientID,
            payload: track.payload,
            formClass: rootGetters.FormClass,
        })

        await commit('SET_STATUS', track.status)

        await commit('SET_META_DATA', track.meta)

        // set form class
        // no need since thats for the pdf
        // await dispatch('SET_FORM_CLASS', formClass);

        return state
    },

    async setPrefillData({ commit, rootGetters, state }, payload) {
        let privateInsuranceCoverage = payload.InsuranceProvider
        payload = {
            ...payload,
            rphfirstname: `${rootGetters.UserProfile.firstName} ${rootGetters.UserProfile.lastName}`,
            ocp: rootGetters.UserProfile.ocp,
            PREFERREDLANGUAGERADIO: 'languageEng',
            'COMM PREFERNCE': 'pharmPHONE',
            'DRUG NAME': 'Praluent',
            'DRUG DIRECTIONS': 'Injection',
            'DRUG EXPECTED': '1 year',
        }
        if (privateInsuranceCoverage) {
            if (privateInsuranceCoverage[0].apikey === 'odb' && privateInsuranceCoverage.length < 2) {
                privateInsuranceCoverage = privateInsuranceCoverage[0]
                payload = {
                    ...payload,
                    'PROVINCIAL COVERAGE RADIO': 'provincialCoverageYes',
                    'INSURANCE CERTIFICATE': privateInsuranceCoverage.PlanID,
                    'INSURANCE GROUP': privateInsuranceCoverage.GroupID,
                    'INSURANCE ID': privateInsuranceCoverage.PlanID,
                    'INSURANCE CARRIER': privateInsuranceCoverage.CarrierID,
                    'INSURANCE NAME': privateInsuranceCoverage.name,
                }
                signatureCoordinates.forEach(insurance => {
                    // console.log('sig private',privateInsuranceCoverage)
                    if (insurance.name == privateInsuranceCoverage.name) {
                        payload = {
                            ...payload,
                            signatureCoords: insurance,
                        }
                    }
                })
            } else if (privateInsuranceCoverage.length > 1 && privateInsuranceCoverage[0].apikey === 'odb') {
                privateInsuranceCoverage = privateInsuranceCoverage[1]
                payload = {
                    ...payload,
                    'PROVINCIAL COVERAGE RADIO': 'provincialCoverageYes',
                    'INSURANCE CERTIFICATE': privateInsuranceCoverage.PlanID,
                    'INSURANCE GROUP': privateInsuranceCoverage.GroupID,
                    'INSURANCE ID': privateInsuranceCoverage.PlanID,
                    'INSURANCE CARRIER': privateInsuranceCoverage.CarrierID,
                    'INSURANCE NAME': privateInsuranceCoverage.name,
                }

                // for sigCoords
                signatureCoordinates.forEach(insurance => {
                    if (insurance.name == privateInsuranceCoverage.name) {
                        payload = {
                            ...payload,
                            signatureCoords: insurance,
                        }
                    }
                })
            } else {
                privateInsuranceCoverage = privateInsuranceCoverage[0]
                payload = {
                    ...payload,
                    'PROVINCIAL COVERAGE RADIO': 'provincialCoverageNo',
                    'INSURANCE CERTIFICATE': privateInsuranceCoverage.PlanID,
                    'INSURANCE GROUP': privateInsuranceCoverage.GroupID,
                    'INSURANCE ID': privateInsuranceCoverage.PlanID,
                    'INSURANCE CARRIER': privateInsuranceCoverage.CarrierID,
                    'INSURANCE NAME': privateInsuranceCoverage.name,
                }
                signatureCoordinates.forEach(insurance => {
                    if (insurance.name == privateInsuranceCoverage.name) {
                        payload = {
                            ...payload,
                            signatureCoords: insurance,
                        }
                    }
                })
            }
            insuranceFormNames.find(insurance => {
                if (privateInsuranceCoverage.name === 'Ontario Drug Benefit') {
                    payload = {
                        ...payload,
                        insuranceCode: 'LU',
                    }
                    if (insurance.code == payload.insuranceCode) {
                        payload = {
                            ...payload,
                            insuranceCompany: insurance.insuranceCompany,
                            insuranceCode: insurance.code,
                            formName: insurance.formName,
                        }
                    }
                }
                if (insurance.code == privateInsuranceCoverage.code) {
                    payload = {
                        ...payload,
                        insuranceCompany: insurance.insuranceCompany,
                        insuranceCode: insurance.code,
                        formName: insurance.formName,
                    }
                }
            })
        } else {
            return []
        }

        if (payload.GENDER === 'M') {
            payload = {
                ...payload,
                'PATIENT GENDER': 'patientGenderMale',
            }
        } else if (payload.GENDER === 'F') {
            payload = {
                ...payload,
                'PATIENT GENDER': 'patientGenderFemale',
            }
        } else {
            payload = {
                ...payload,
                'PATIENT GENDER': null,
            }
        }

        if (payload.Relationship === '0') {
            payload = {
                ...payload,
                RELATIONSHIPSELECT: 'Self',
                'PATIENT IS MEMBER': 'isPaYes',
                'MEMBER FIRST NAME': payload['FIRST NAME'],
                'MEMBER LAST NAME': payload['LAST NAME'],
                'MEMBER DATE OF BIRTH': payload['DATE OF BIRTH'],
            }
            if (payload.GENDER === 'M') {
                payload = {
                    ...payload,
                    GENDERSELECT: 'memberInfoM',
                }
            } else if (payload.GENDER === 'F') {
                payload = {
                    ...payload,
                    GENDERSELECT: 'memberInfoF',
                }
            } else {
                payload = {
                    ...payload,
                    GENDERSELECT: null,
                }
            }
        } else if (payload.Relationship === '1') {
            payload = {
                ...payload,
                RELATIONSHIPSELECT: 'Spouse',
                'PATIENT IS MEMBER': 'isPaNo',
                GENDERSELECT: null,
                'MEMBER FIRST NAME': '',
                'MEMBER LAST NAME': '',
                'MEMBER DATE OF BIRTH': '',
            }
        } else if (payload.Relationship === '2') {
            payload = {
                ...payload,
                RELATIONSHIPSELECT: 'Dependent',
                'PATIENT IS MEMBER': 'isPaNo',
                GENDERSELECT: null,
                'MEMBER FIRST NAME': '',
                'MEMBER LAST NAME': '',
                'MEMBER DATE OF BIRTH': '',
            }
        }

        return await commit('SET_PRALUENT_PREFILL_DATA', payload)
    },
    async setProcess({ rootGetters, commit, state }, payload) {
        const data = {
            trackID: state.trackID,
            pharmacyID: rootGetters.PharmacyProfile._id,
            payload: {
                ...state.payload,
                ...payload,
                pdfReferences: {
                    ...state.payload.pdfReferences,
                    ...payload.pdfReferences,
                },
            },
        }

        const token = await this._vm.$auth.getTokenSilently()
        await trackService.update(data, token).then(resp => resp.data)

        return await commit('SET_PROCESS', {
            payload: data.payload,
        })
    },
    async setProcessMetaData({ commit, state }, metaData) {
        const data = {
            trackID: state.trackID,
            meta: {
                ...state.meta,
                ...metaData,
            },
        }

        const token = await this._vm.$auth.getTokenSilently()
        await trackService.update(data, token).then(resp => resp.data)

        return await commit('SET_META_DATA', data.meta)
    },
    async getProcessStatus({ rootGetters }, PatientID) {
        let track = {}
        try {
            const token = await this._vm.$auth.getTokenSilently()
            track = await trackService
                .getByPatient(
                    {
                        patientID: PatientID,
                        pharmacyID: rootGetters.PharmacyProfile._id,
                        formClass: rootGetters.FormClass,
                    },
                    token,
                )
                .then(resp => resp.data)
        } catch (e) {
            // console.error(e);
        }

        const patientProcessSteps = steps.reduce((cur, step) => {
            let pdf = {}
            try {
                // only patient with track record
                if (track.payload) {
                    pdf = {
                        pdf: track.payload.pdfReferences && step.pdfId ? track.payload.pdfReferences[step.pdfId] : null,
                        done: track.payload[step.id] || false,
                    }
                }
            } catch (e) {
                console.error(e)
            }

            return {
                ...cur,
                [step.id]: {
                    ...step,
                    ...pdf,
                },
            }
        }, {})

        return {
            status: track.status,
            steps: patientProcessSteps,
        }
    },

    // FIXME it should subscribe within the process
    // async pdfUpdateData({
    //     dispatch
    // }, pdfState) {
    //     dispatch('setProcess', {

    //     })
    // },
    async setProcessStatus({ rootGetters, commit, state }, status) {
        const data = {
            trackID: state.trackID,
            pharmacyID: rootGetters.PharmacyProfile._id,
            status,
        }

        const token = await this._vm.$auth.getTokenSilently()
        await trackService.update(data, token).then(resp => resp.data)

        return await commit('SET_STATUS', data.status)
    },

    async resetProcess({ commit }) {
        return await commit('RESET_PROCESS')
    },
}

const getters = {
    TrackProcess(state) {
        return state
    },
    TrackMeta(state) {
        return state.meta
    },
    PraluentPatient(state) {
        return state.praluentPatient
    },

    GET_PRALUENT_PATIENT_DATA_WITH_MEDICATION_TABLE(state) {
        const medTable = state.praluentPatient.medicationTable.reduce((acc, curr, index) => {
            for (const [key, value] of Object.entries(curr)) {
                acc = {
                    ...acc,
                    [`${key}${index + 1}`]: value,
                }
            }

            return acc
        }, {})

        return medTable
    },
    GET_PATIENTCODE(state) {
        let insuranceProvider
        let insuranceCarrierID
        if (state.praluentPatient.InsuranceProvider) {
            if (
                state.praluentPatient.InsuranceProvider[0].apikey === 'odb'
        && state.praluentPatient.InsuranceProvider.length > 1
            ) {
                insuranceProvider = state.praluentPatient.InsuranceProvider[1].PlanCode
                insuranceCarrierID = state.praluentPatient.InsuranceProvider[1].CarrierID
            } else {
                insuranceProvider = state.praluentPatient.InsuranceProvider[0].PlanCode
                insuranceCarrierID = state.praluentPatient.InsuranceProvider[0].CarrierID
            }
            if (
                insuranceProvider === 'NEX'
        || insuranceProvider === 'NX'
        || insuranceProvider === 'RXP'
        || insuranceProvider === 'BC'
        || insuranceProvider === 'GS'
        || insuranceProvider === 'SSQ'
            ) {
                return `${insuranceProvider}`
            }
            if (
                insuranceCarrierID === '12'
        || insuranceCarrierID === '11'
        || insuranceCarrierID === '02'
        || insuranceCarrierID === '16'
            ) {
                return `${insuranceProvider}${insuranceCarrierID}`
            }

            return `${insuranceProvider}`
        }

        return []
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
}
