import { mdiPencilCircleOutline, mdiPlusCircleOutline } from '@mdi/js'

export default [
    {
        title: 'Optimization',
        icon: mdiPlusCircleOutline,

        // badge: '3',
        // badgeColor: 'warning',
        children: [
            {
                title: 'Praluent',
                icon: mdiPencilCircleOutline,
                to: 'health-optimization-praluent-dashboard',
            },
        ],
    },
]
