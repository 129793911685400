/* eslint-disable import/order */
/* eslint-disable import/no-cycle */
import { homeGuard } from '@/auth'
import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboards from './dashboards'
import formHub from './formHub'
import groupHub from './groupHub'
import healthOptimization from './healthOptimization'
import logger from './logger'
import patient from './patient'
import root from './root'
import services from './services'
import shipping from './shipping'
import support from './support'
import user from './user'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    beforeEnter: homeGuard,
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
    children: [
      ...formHub,
      ...shipping,
      ...services,
      ...user,
      ...support,
      ...groupHub,
      ...patient,
      ...dashboards,
      ...logger,
      ...healthOptimization,
    ],
  },
  ...root,

  // {
  //   path: '*',
  //   redirect: 'error-404',
  // },
  {
    path: '*',
    redirect: 'home',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})

export default router
