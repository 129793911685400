<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <template #systemBar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div class="d-flex align-center">
        <v-icon
          v-if="$vuetify.breakpoint.mdAndDown"
          class="me-3"
          @click="toggleVerticalNavMenuActive"
        >
          {{ icons.mdiMenu }}
        </v-icon>
      </div>
      <v-spacer />
      <v-icon
        color="primary"
        medium
      >
        {{ icons.mdiPill }}
      </v-icon>
      <div
        id="pharmacySelector"
      >
        <v-select
          v-model="activePharmacyID"
          solo
          full-width
          :items="accNumbers"
          menu-props="auto"
          label="Select"
          dense
          single-line
          class="pt-4 pl-2"
          @change="getSelectedPharmacyInfoOnChangeNew(activePharmacyID)"
        ></v-select>
      </div>
      <!-- <v-spacer /> -->
      <div>
        <app-bar-patient-search class="tw-pl-6"></app-bar-patient-search>
      </div>
      <v-spacer />
      <app-bar-help-button></app-bar-help-button>
      <app-bar-logger></app-bar-logger>
      <div class="tw-pr-8">
        <app-bar-user-menu></app-bar-user-menu>
      </div>
    </template>
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search>
        </div>
      </div>
    </template>
    <!-- Alerts -->
    <v-dialog
      v-model="activePharmacyUpdated"
      max-width="500px"
    >
      <v-alert type="success">
        <p class="text-center">
          Selected Pharmacy is now:
        </p>
        <p class="text-center">
          <b> {{ this.PharmacyProfile.name }}</b>
        </p>
      </v-alert>
    </v-dialog>
    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://pharmaguide.ca"
          class="text-decoration-none"
        >PharmaGuide Inc.</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
        <div class="align-center d-none d-md-flex">
          <span>Hand-crafted &amp; Made with</span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import { getVuetify } from '@core/utils'
import {
    mdiHeartOutline, mdiMenu, mdiPill, mdiWifiRefresh
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { mapGetters, mapState } from 'vuex'
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import appBarSearchData from '@/assets/app-bar-search-data'
import AppBarHelpButton from '@/components/app-bar/AppBarHelpButton.vue'
import AppBarPatientSearch from '@/components/app-bar/AppBarPatientSearch.vue'
import AppBarUserMenu from '@/components/app-bar/AppBarUserMenu.vue'
import navMenuItems from '@/navigation/vertical'
import { adminService, userService } from '@/render/api/zarya'
import { SystemConnection } from '@/services/integrations'
import AppBarLogger from '@/components/app-bar/AppBarLogger.vue'

export default {
    name: 'PhoxLayoutTemplate',
    components: {
        LayoutContentVerticalNav,
        AppBarPatientSearch,
        AppBarSearch,
        AppBarUserMenu,
        AppBarHelpButton,
        AppBarLogger,
    },

    setup() {
        const $vuetify = getVuetify()

        // Search
        const appBarSearchQuery = ref('')
        const shallShowFullSearch = ref(false)
        const maxItemsInGroup = 5
        const totalItemsInGroup = ref({
            pages: 0,
            files: 0,
            contacts: 0,
        })
        watch(appBarSearchQuery, () => {
            totalItemsInGroup.value = {
                pages: 0,
                files: 0,
                contacts: 0,
            }
        })

        // NOTE: Update search function according to your usage
        const searchFilterFunc = (item, queryText, itemText) => {
            if (item.header || item.divider) return true

            const itemGroup = (() => {
                if (item.to !== undefined) return 'pages'
                if (item.size !== undefined) return 'files'
                if (item.email !== undefined) return 'contacts'

                return null
            })()

            const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

            if (isMatched) {
                if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
                else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
                else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
            }

            return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
        }

        // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
        const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
            if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
                toggleVerticalNavMenuActive()
            }
        }

        return {
            navMenuItems,
            handleShallShowFullSearchUpdate,

            // Search
            appBarSearchQuery,
            shallShowFullSearch,
            appBarSearchData,
            searchFilterFunc,
            icons: {
                mdiMenu,
                mdiHeartOutline,
                mdiPill,
                mdiWifiRefresh,
            },
        }
    },
    computed: {
        ...mapGetters(['UserProfile', 'PharmacyProfile', 'PatientProfile']),
        ...mapState(['User'], ['PatientProfileStore']),
    },
    data() {
        return {
            userProfile: this.$store.getters.UserProfile,
            activePharmacyUpdated: false,
            userPharmacies: '',
            selectedActivePharmacy: '',
            activePharmacyID: '',
            activePharmacyKey: 0,
            accNumbers: [],
            patientFullName: '',
            statusbarkey: 0,
        }
    },
    async mounted() {
    // this.$root.$on('Refresh_Table', () => {
    //   this.$store.dispatch('resetPatientData')
    //   this.$store.dispatch('resetState')
    //   this.activePharmacyKey += 1
    //   this.getUserInfo()
    //   this.refreshConnectionEmit(this.activePharmacyID)
    // })

        // this.bootIntercom()
        await this.forceRerender()

        // this.$root.$emit('Refresh_Rx_List')

        // this.initialPharmacy()

        this.$root.$on('Refresh_Connection', () => {
            if (sessionStorage.pharmacy) {
                this.refreshConnectionEmit(sessionStorage.pharmacy)
            } else {
                // this.getSelectedPharmacyInfoOnChange(this.activePharmacyID)
                console.log('No Pharmacy Selected')
            }
        })

        // this.getUserInfo()
        this.$root.$on('Refresh_Status_Bar', patientID => {
            this.updateIntercom()
        })
        this.$root.$on('Refresh_Table', patientID => {
            this.updateIntercom()
        })
    },
    methods: {
        initialPharmacy() {
            if (this.activePharmacyID === '') {
                this.activePharmacyID = this.accNumbers[0].value
                console.log(this.accNumbers[0].value)

                // this.getSelectedPharmacyInfoOnChangeNew(this.activePharmacyID)
                console.log('initial Pharmacy', this.activePharmacyID)
                this.$root.$emit('Refresh_Connection')
            } else {
                // this.getSelectedPharmacyInfoOnChangeNew(this.activePharmacyID)
                console.log('existing Pharmacy', this.activePharmacyID)
                this.$root.$emit('Refresh_Connection')
            }
        },
        resetpage() {
            this.$root.$emit('Refresh_Connection')
        },
        updateIntercom() {
            const id = this.$auth.user.sub.replace('auth0|', '')
            this.$intercom.update({
                user_id: id,
                name: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
                email: this.$store.state.User.user.email,
                pharmacy: this.$store.state.User.pharmacy.name,
                'Company Name': this.$store.state.User.pharmacy.name,

                DBType: this.$store.state.User.pharmacy.serverConfig.database,
                PharmacyEmail: this.$store.state.User.pharmacy.email,
                'Referral url': this.$route.fullPath,
            })
        },
        async forceRerender() {
            this.$store.commit('appConfig/UPDATE_APP_BAR_TYPE', 'hidden')
            this.$store.dispatch('resetPatientData')
            this.$store.dispatch('resetState')
            this.activePharmacyKey += 1
            await this.getUserInfo()
            this.refreshConnectionEmit(this.activePharmacyID)
        },
        setLocalItem() {
            localStorage.setItem('userAbility', this.$store.state.User.pharmacy.accountTier)
        },
        async getUserInfo() {
            const id = this.$auth.user.sub.replace('auth0|', '')
            const token = await this.$auth.getTokenSilently()
            if (sessionStorage.pharmacy) {
                this.$store.dispatch('getUser', token)
                this.activePharmacyID = sessionStorage.pharmacy
                this.userPharmacies = await userService
                    .getUserPharmacies(
                        {
                            id,
                        },
                        token,
                    )
                    .then(response => response)

                this.accNumbers = await this.userPharmacies.map(pharmacy => ({
                    text: pharmacy.name,
                    value: pharmacy._id,
                    accNumber: pharmacy.accNumber,
                }))
            } else {
                this.userPharmacies = await userService
                    .getUserPharmacies(
                        {
                            id,
                        },
                        token,
                    )
                    .then(response => response)

                this.accNumbers = await this.userPharmacies.map(pharmacy => ({
                    text: pharmacy.name,
                    value: pharmacy._id,
                    accNumber: pharmacy.accNumber,
                }))

                const accessToken = await this.$auth.getTokenSilently()
                this.$store.dispatch('getUser', accessToken)

                // FIXME You can use the index to set default pharmacy from auth0 user_metadata in the future
                const selectedActivePharmacy = await adminService.getPharmacyByID(this.accNumbers[0].value, accessToken)
                await this.$store.dispatch('getSelectedPharmacyInfo', selectedActivePharmacy)
                this.activePharmacyID = this.accNumbers[0].value
            }
        },

        // to go back to v-select remove activePharmacyID from inside the brackets and make adminService.getPharmacyById(this.activePharamcyID)
        async getSelectedPharmacyInfo(activePharmacyID) {
            sessionStorage.setItem('pharmacy', activePharmacyID)
            const accessToken = await this.$auth.getTokenSilently()
            this.$store.dispatch('getUser', accessToken)
            const selectedActivePharmacy = await adminService.getPharmacyByID(activePharmacyID, accessToken)
            await this.$store.dispatch('getSelectedPharmacyInfo', selectedActivePharmacy)

            //   const $system = new SystemConnection(selectedActivePharmacy.serverConfig, selectedActivePharmacy.messageQueueConfig)
            //   await $system.init()
            //   this.$root.$system = $system
            //   window.$system = $system
            this.forceRerender()

            this.activePharmacyUpdated = true

            // delay the execution of the function
            setTimeout(() => {
                this.$store.dispatch('resetPatientData')
                this.$store.dispatch('PatientProfileStore/resetPatientProfileState')
                this.$root.$emit('Refresh_User_List', this.$store.state.User.pharmacy._id)
                this.$root.$emit('Refresh_Table')
            }, 1000)

            window.setTimeout(() => {
                this.activePharmacyUpdated = false
            }, 2000)
        },
        async getSelectedPharmacyInfoOnChangeNew(activePharmacyID) {
            console.log(activePharmacyID)
            sessionStorage.setItem('pharmacy', activePharmacyID)

            await window.$system.mqConn.rpc.end(false, {
                reasonCode: 'MQTT200',
                properties: {
                    reasonString: 'Switching pharmacy handler',
                },
            })
            const accessToken = await this.$auth.getTokenSilently()
            const selectedActivePharmacy = await adminService.getPharmacyByID(activePharmacyID, accessToken)
            await this.$store.dispatch('getSelectedPharmacyInfo', selectedActivePharmacy)

            const $system = new SystemConnection(selectedActivePharmacy.serverConfig, {
                ...selectedActivePharmacy.messageQueueConfig,
                uniqueId: `docs-${this.$auth.user.email}`,
            })
            await $system.init()
            this.$root.$system = $system
            window.$system = $system

            this.activePharmacyUpdated = true

            // delay the execution of the function
            setTimeout(() => {
                this.$store.dispatch('resetPatientData')
                this.$store.dispatch('PatientProfileStore/resetPatientProfileState')
                this.$root.$emit('Refresh_User_List', this.$store.state.User.pharmacy._id)
                this.$root.$emit('Refresh_Rx_List')
                this.$root.$emit('Refresh_Table')
            }, 1000)

            window.setTimeout(() => {
                this.activePharmacyUpdated = false
            }, 2000)
            if (this.$route.path !== '/home') {
                this.$router.push({
                    name: 'home',
                })
            }
        },
        async getSelectedPharmacyInfoOnChange(activePharmacyID) {
            sessionStorage.setItem('pharmacy', activePharmacyID)
            if (this.$route.path !== '/home') {
                this.$router.push({
                    name: 'home',
                })
            }
            await window.$system.mqConn.rpc.end(false, {
                reasonCode: 'MQTT200',
                properties: {
                    reasonString: 'Switching pharmacy handler',
                },
            })
            const accessToken = await this.$auth.getTokenSilently()
            const selectedActivePharmacy = await adminService.getPharmacyByID(activePharmacyID, accessToken)
            await this.$store.dispatch('getSelectedPharmacyInfo', selectedActivePharmacy)

            const $system = new SystemConnection(selectedActivePharmacy.serverConfig, {
                ...selectedActivePharmacy.messageQueueConfig,
                uniqueId: `docs-${this.$auth.user.email}`,
            })
            await $system.init()
            this.$root.$system = $system
            window.$system = $system
            console.log(this.$store.state.User.pharmacy.serverConfig.databaseType)
            this.activePharmacyUpdated = true

            // delay the execution of the function
            setTimeout(() => {
                this.$store.dispatch('resetPatientData')
                this.$store.dispatch('PatientProfileStore/resetPatientProfileState')
                this.$root.$emit('Refresh_User_List', this.$store.state.User.pharmacy._id)
                this.$root.$emit('Refresh_Table')
            }, 1000)

            window.setTimeout(() => {
                this.activePharmacyUpdated = false
            }, 2000)
        },
        async refreshConnectionEmit(activePharmacyID) {
            sessionStorage.setItem('pharmacy', activePharmacyID)

            await window.$system.mqConn.rpc.end(false, {
                reasonCode: 'MQTT200',
                properties: {
                    reasonString: 'Switching pharmacy handler',
                },
            })
            const accessToken = await this.$auth.getTokenSilently()
            const selectedActivePharmacy = await adminService.getPharmacyByID(activePharmacyID, accessToken)
            await this.$store.dispatch('getSelectedPharmacyInfo', selectedActivePharmacy)

            const $system = new SystemConnection(selectedActivePharmacy.serverConfig, {
                ...selectedActivePharmacy.messageQueueConfig,
                uniqueId: `docsEmit-${this.$auth.user.email}`,
            })
            await $system.init()
            this.$root.$system = $system
            window.$system = $system

            this.activePharmacyUpdated = true

            // delay the execution of the function
            setTimeout(() => {
                this.$store.dispatch('resetPatientData')
                this.$store.dispatch('PatientProfileStore/resetPatientProfileState')
                this.$root.$emit('Refresh_User_List', this.$store.state.User.pharmacy._id)
                this.$root.$emit('Refresh_Table')
            }, 1000)

            window.setTimeout(() => {
                this.activePharmacyUpdated = false
            }, 2000)
        },
    },
}
</script>

<style lang="scss">
#pharmacySelector > div > div.v-input__prepend-outer > div > span {
  color: #2183f5;
}

nav.app-navigation-menu {
  z-index: 4;
}

#app .v-application--wrap .v-system-bar {
  padding: 15px 0px !important;
  background-color: white !important;
  box-shadow: none !important;
  // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.5));
  // z-index: 51 !important;

  .v-toolbar__title {
    font-size: 0.9rem;

    // theme doesn't have a way to customize it directly
    & > .font-weight-heavy {
      font-weight: 500;
    }
  }
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
      max-width: calc(100% - 3rem * 2);
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    :v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
