import axios from '../http'
import config from './config'

const getPatientFileById = async (token, id, pharmacyId) => {
  try {
    const res = await axios.get(`${config.api}patient/?id=${id}&pharmacyId=${pharmacyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

const getPatientFiles = async (token, pharmacyId) => {
  try {
    const res = await axios.get(`${config.api}patient/all/${pharmacyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

const DeletePatientById = async (token, id) => {
  try {
    const res = await axios.delete(`${config.api}patient/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

const postPatientFile = async (token,_id, userID, pharmacyID, profile, meds, props) => await axios.post(
  `${config.api}patient`, {
    _id,userID, pharmacyID, profile, meds, props,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
).then(res => res.data)

const updatePatientFile = async (token, id, userID, pharmacyID, profile, meds, props) => await axios.put(
  `${config.api}patient/update`, {
    id, userID, pharmacyID, profile, meds, props,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
).then(res => res.data)

export default {
  getPatientFileById,
  getPatientFiles,
  postPatientFile,
  DeletePatientById,
  updatePatientFile,
}
