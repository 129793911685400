<template>
  <span>
    <v-dialog
      v-show="show"
      v-model="isDialogVisible"
      v-hotkey="keymap"
      persistent
      max-width="1000px"
      class="mx-auto"
      transition="dialog-bottom-transition"
    >
      <!-- <v-dialog v-model="isDialogVisible" fullscreen hide-overlay transition="dialog-bottom-transition"> -->
      <template #activator="{ on: menu, attrs }">
        <v-tooltip
          bottom
          color="primary"
        >
          <template #activator="{ on: tooltip }">
            <v-icon
              :color="ptButtonIconColor"
              medium
            >
              {{ icons.mdiAccountSearch }}
            </v-icon>
            <v-btn
              class="tw-ml-2"
              color="primary"
              dark
              :outlined="ptButton"
              small
              v-bind="attrs"
              data-intercom-target="appBarPatientSearchButton"
              v-on="{ ...tooltip, ...menu }"
            >

              <span v-if="Patient.data">
                {{ Patient.data['FIRST NAME'] }} {{ Patient.data['LAST NAME'] }}
              </span>
              <span v-if="!Patient.data['FIRST NAME']">
                Search for a patient
              </span>
            </v-btn>
          </template>
          <span>Click here to search for a patient (F1)</span>
        </v-tooltip>
      </template>
      <v-card data-intercom-target="appBarPatientSearchDialog">
        <v-form
          ref="form"
          :key="appBarPatientSearchKey"
          v-model="valid"
          class="multi-col-validation"
          @submit.native="updateList"
        >
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
                <h3 class="tw-text-blue-900 tw-text-2xl tw-font-bold dark:tw-text-white dark:tw-text-opacity-50">Patient Search:</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  v-model="patient.lastName"
                  label="Last Name"
                  outlined
                  placeholder="Please enter at least 3 characters"
                  data-intercom-target="patientSearchModalLastName"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  v-model="patient.firstName"
                  data-intercom-target="patientSearchModalFirstName"
                  label="First Name"
                  outlined
                  placeholder="Please enter at least 3 characters"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-list
              v-show="!isLoading && list.length"
              data-intercom-target="patientSearchModalPAtientSelect"
              rounded
              class="overflow-y-auto"
              max-height="300"
            >
              <v-divider inset></v-divider>
              <v-list-item-content>
                <v-list-item
                  v-for="(paitent, index) in list"
                  :key="index"
                  @click="processPatientInfo(paitent)"
                >
                  <v-list-item-avatar>
                    <v-icon
                      medium
                      color="primary"
                      filled
                      dark
                    >
                      folder_open
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>{{ paitent.FirstName }} {{ paitent.LastName }}</v-list-item-title>
                  <v-list-item-title>
                    DOB:
                    {{ $moment(paitent.DOB).format("DD-MM-YYYY") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-content>
            </v-list shaped>
            <v-list v-show="!isLoading && hasSearched && !list.length && msg">
              <v-list-item ripple>
                {{ msg }}
              </v-list-item>
            </v-list>
          </v-container>
          <v-card-actions class="justify-center">
            <!-- <v-btn large color="error" outlined @click="newPatient">
                        Reset <v-icon dark left>
                            {{ icons.mdiCloudRefresh }}
                        </v-icon>
                    </v-btn> -->
            <!-- <v-btn large color="error" outlined @click="clearFieds">
                        Clear <v-icon dark left>
                            {{ icons.mdiBackspaceOutline }}
                        </v-icon>
                    </v-btn> -->
            <v-btn
              large
              color="warning"
              @click="initialState()"
            >
              Close <v-icon
                dark
                right
              >
                {{ icons.mdiMinusCircleOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              large
              color="primary"
              type="submit"
              value="Submit"
              @click="updateList"
            >
              Submit <v-icon
                dark
                right
              >
                {{ icons.mdiTextBoxSearchOutline }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <!-- Actions -->

        <!-- Alerts -->
        <v-dialog
          v-model="isLoading"
          hide-overlay
          persistent
          width="300"
        >
          <v-card
            color="primary"
            dark
          >
            <v-card-text class="pt-3">
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="successload"
          hide-overlay
          width="300"
        >
          <v-alert
            type="success"
            class="mb-0"
          >Patient Loaded</v-alert>
        </v-dialog>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
// import DateTimeLogger from '@components/common/ui/Form/Datepicker/DateTimeLogger'
import {
  alphaDashValidator,
  alphaValidator,
  between,
  confirmedValidator,
  emailValidator,
  integerValidator,
  lengthValidator,
  passwordValidator,
  regexValidator,
  required,
  urlValidator,
} from '@core/utils/validation'
import {
  mdiAccountSearch,
  mdiBackspaceOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiCloudRefresh,
  mdiMinusCircleOutline,
  mdiTextBoxSearchOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Promise } from 'bluebird'
import { debounce } from 'lodash'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AppBarPatientnamesearch',

  components: {
    // datetime: Datetime,
  },
  setup() {
    // validate
    const requiredField = ref('')
    const numberBetween10to20 = ref('')
    const onlyConsistNumber = ref('')
    const matchRegularEx = ref('')
    const onlyAlphabeticCharacters = ref('')
    const specifiedLength = ref('')
    const password = ref('')
    const digits = ref('')
    const repeatePassword = ref('')
    const onlyAlphabeticNumbersDashesUnderscores = ref('')
    const email = ref('')
    const validURL = ref('')
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    //

    const isDialogVisible = ref(false)

    return {
      isDialogVisible,
      icons: {
        mdiCloudRefresh,
        mdiMinusCircleOutline,
        mdiCheckboxMarkedCircleOutline,
        mdiAccountSearch,
        mdiBackspaceOutline,
        mdiTextBoxSearchOutline,
      },

      // validation
      requiredField,
      numberBetween10to20,
      onlyConsistNumber,
      matchRegularEx,
      onlyAlphabeticCharacters,
      specifiedLength,
      password,
      digits,
      repeatePassword,
      onlyAlphabeticNumbersDashesUnderscores,
      email,
      validURL,
      validate,
      form,
      specifiedLength,
      emailValidator,
      passwordValidator,
      between,
      integerValidator,
      regexValidator,
      alphaValidator,
      urlValidator,
      confirmedValidator,
      lengthValidator,
      required,
      alphaDashValidator,
    }
  },
  computed: {
    ptButton() {
      if (!this.Patient.data['FIRST NAME']) {
        return false
      }

      return true
    },
    ptButtonIconColor() {
      if (!this.Patient.data['FIRST NAME']) {
        return 'primary'
      }

      return 'primary'
    },
    ...mapGetters(['PharmacyProfile', 'UserProfile']),
    ...mapState(['User', 'Patient']),
    keymap() {
      return {
        // 'esc+ctrl' is OK.
        f1: this.toggle,
        enter: {
          keydown: this.hide,
          keyup: this.show,
        },
      }
    },
  },
  data() {
    return {
      show: true,
      appBarPatientSearchKey: 0,
      userProfile: this.$store.getters.UserProfile,
      loadProfile: false,
      isDialogVisible: false,
      successload: false,
      progress: 0,
      list: [],
      search: {},
      msg: '',
      hasSearched: false,
      isLoading: false,
      patient: {
        firstName: '',
        lastName: '',
        dob: '',
      },
      valid: false,
      user: this.$auth.user.name,
    }
  },
  watch: {
    // on routing scenario
    $route: {
      handler(to) {
        this.getRequiredPatientOn(to.path)
      },
    },
  },
  mounted() {
    this.initialState()
    this.getRequiredPatientOn(this.$route.path)
    console.log(this.Patient)
  },
  methods: {
    getRequiredPatientOn(path) {
      if (
        path.indexOf('/formhub') > -1 &&
        !this.$store.getters.PatientProfile.PatientID
      ) {
        // show patient search
        this.toggle()
      }
      if (
        path.indexOf('/patient') > -1 &&
        !this.$store.getters.PatientProfile.PatientID
      ) {
        // show patient search
        this.toggle()
      }
      if (
        path.indexOf('/services') > -1 &&
        !this.$store.getters.PatientProfile.PatientID
      ) {
        // show patient search
        this.toggle()
      }
    },
    toggle() {
      this.show = !this.show
      this.isDialogVisible = !this.isDialogVisible
    },

    // show() {
    //   this.show = true
    // },
    hide() {
      this.show = false
    },
    clearFieds() {
      this.patient.firstName = ''
      this.patient.lastName = ''
    },
    updateList(e) {
      e.preventDefault()
      this.hasSearched = false
      this.msg = ''
      this.list = [] // always reset the list
      if (
        this.patient.firstName.length < 2 &&
        this.patient.lastName.length < 2
      ) {
        return
      }
      this.debounceFetchPatientsSearch()

      return false
    },
    debounceFetchPatientsSearch() {
      // already initialized ? then use it
      if (this.debounceFunc) {
        this.debounceFunc()
      } else {
        // initialize it
        this.debounceFunc = debounce(() => {
          this.isLoading = true

          // console.log('dbAdapterConn', this.$root.$system.DBAdapterConn)
          this.$root.$system.DBAdapterConn.getSearchPatientsByName({
            firstName: this.patient.firstName,
            lastName: this.patient.lastName,
          }).then(
            results => {
              this.isLoading = false
              if (results.length) {
                this.list = results
              } else {
                this.hasSearched = true
                this.msg = 'No data found'
              }
            },

            // (err)=>{
            //   this.msg = 'Error: please try again.';
            // }
          )
        }, 200)
        this.debounceFunc()
      }
    },
    processPatientInfo(patient) {
      this.isDialogVisible = false
      this.isLoading = true
      this.loadProfile = true
      this.$emit('search-patient')
      Promise.delay(20)
        .then(() => {
          this.progress = 75
          this.$store.dispatch('resetPatientData')
          this.resetState()

          // do the request
          return this.$root.$system.DBAdapterConn.getPatientData(
            patient.PatientID,
          )
        })
        .then(async patientData => {
          this.progress = 100
          await this.$store.dispatch('savePatientData', patientData)
          this.isLoading = false

          return patientData.PatientID
        })
        .then(PatientID => {
          this.routePatient(PatientID)
        })
    },
    routePatient(PatientID) {
      return Promise.delay(20)
        .then(() => {
          this.progress = 100
        })
        .delay(20)
        .then(() => {
          this.resetState()
          this.$root.$emit('Refresh_Table', PatientID)
          this.$root.$emit('Refresh_Medscheck', PatientID)

          // console.log(this.$root.$emit('Refresh_Table', PatientID))
          this.$emit('selected-patient', PatientID)
          this.isDialogVisible = false
          this.successload = true
          window.setTimeout(() => {
            this.successload = false
          }, 1000)
          this.forceRerender()

          // this.$router.push({
          //   name: this.$route.query.next,
          //   query: {
          //     patient: this.$route.query.options?.patient || false,
          //     patientID: PatientID,
          //     c: Math.random()
          //   }
          // }).catch(err => {
          //   // Ignore the vuex err regarding  navigating to the page they are already on.
          //   if (
          //     err.name !== 'NavigationDuplicated' &&
          //     !err.message.includes('Avoided redundant navigation to current location')
          //   ) {
          //     // But print any other errors to the console
          //     console.error(err);
          //   }
          // });
        })
    },
    newPatient() {
      this.$store.dispatch('resetPatientData')
      this.resetState()
      location.reload()
    },
    resetState() {
      this.progress = 0
      this.loadProfile = false
      this.list = []
      this.patient.firstName = ''
      this.patient.lastName = ''
      this.medicationList = ''
      this.patientData = ''
      this.patientProfile = ''
    },
    forceRerender() {
      this.appBarPatientSearchKey += 1
    },
    initialState() {
      this.isDialogVisible = false
      this.list = []
      this.patient.firstName = ''
      this.patient.lastName = ''
    },
  },
}
</script>

<style scoped>
.v-btn--fab.v-size--default {
  height: 20px;
  width: 20px;
  margin-left: 16px;
}
</style>
