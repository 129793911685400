import axios from '../http'
import config from './config'

const update = async ({
    trackID,
    pharmacyID,
    payload,
    status,
    meta
}, accessToken) => await axios.post(
    `${config.api}track/${trackID}?pharmacyID=${pharmacyID}`, {
        payload,
        status,
        meta,
    }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
)

const create = async ({
    patientID,
    pharmacyID,
    payload,
    formClass,
    patientFirstName,
    patientLastName,
    consent
},
accessToken,) => await axios.post(
    `${config.api}track`, {
        patientID,
        pharmacyID,
        payload,
        formClass,
        patientFirstName,
        patientLastName,
        consent,
    }, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
)

const get = async (trackID, pharmacyID, accessToken) => await axios.get(
    `${config.api}track/${trackID}?pharmacyID=${pharmacyID}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }
)

const getByPatient = async ({
    patientID,
    pharmacyID,
    formClass,
    trackId
}, accessToken) => await axios.get(
    `${config.api}track/patient/${formClass}/${patientID}/${trackId}?pharmacyID=${pharmacyID}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }
)

const getActionList = async ({
    patientID,
    formClass,
    token,
    pharmacyID,
}) => {
    if (!patientID) {
        patientID = ''
    }

    return await axios.post(`${config.api}track/actions/${formClass}/${patientID}`, { pharmacyID }, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

const getBySubmissionID = async ({
    submissionID,
    pharmacyID,
    formClass,
    key
}, accessToken) => await axios.get(
    `${config.api}track/patient/${formClass}/pdf/${key}/${submissionID}?pharmacyID=${pharmacyID}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }
)

export default {
    create,
    get,
    update,
    getByPatient,
    getActionList, // not used in V2
    getBySubmissionID, // not used in V2
}
