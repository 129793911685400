let api = process.env.VUE_APP_API_URL
const debug = false

if (process.env.NODE_ENV === 'development' && debug === true) {
    api = 'http://localhost:3000/'
}

export default {
    api,
}
